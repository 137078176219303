import React, {useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import BidPlaced from './BidPlaced';
import OfferPlaced from './OfferPlaced';
import OrderPlaced from './OrderPlaced';
import ApiService from '../../../services/api.service';

const PlacedView = ({setTab, tab, product, DefferPrice, valueToMatch, tabWiseData }) => {
    const { type } = useParams();
    const [listingUser, setListingUser] = useState();

    // Get the listing user details from product
    const getUserProfile = (sellerSid) =>{
        try{
            ApiService.getMyProfile(sellerSid).then(
                response => {
                    setListingUser(response.data);
                },
                err => {
                    console.error('Error occurred in getUserProfile--', err);
                }
            ).finally(() => {
            });
        }catch(err){
            console.error("Error occurred in getUserProfile--", err);
        }
    }
    
    useEffect(()=>{
        if(product?.postedBy){
            getUserProfile(product?.postedBy);
        }
    },[product])

    return (
        <div className="order-placed-view">
            {type === "buy" && <OrderPlaced {...{setTab, tab, tabWiseData, type, product, listingUser}} />}
            {type === "trade" && <OfferPlaced  {...{setTab, tab, valueToMatch, tabWiseData, type, product, listingUser }} />}
            {type === "bid" && <BidPlaced {...{setTab, tab, product, tabWiseData, type, listingUser}} />}
        </div>
    )
}

export default PlacedView;