import React, { Fragment, useEffect, useContext, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../../../Shared/Modal";
import GLOBAL_CONSTANTS from "../../../../Constants/GlobalConstants";
import useToast from "../../../../commons/ToastHook";
import Spinner from "rct-tpt-spnr";
import _ from 'lodash';
import $ from "jquery";
import ApiService from "../../../../services/api.service";
import { AppContext } from "../../../../contexts/AppContext";
import { useAuthState } from "../../../../contexts/AuthContext";
import bgCheckmarkIcon from '../../../../assets/images/icon/bg_checkmark.png';
import bgFailureIcon from '../../../../assets/images/Failure.png';
import './PaymentGateway.css'

const OrderPlacedModal = ({
    show,
    setShow,
    hideCloseIcon = true,
    product,
    listingUser,
    setShowConfirmMsg

}) => {

    const userDetails = useAuthState();
    const Toast = useToast();
    const spinner = useContext(Spinner);
    const { paymentStatus, setPaymentStatus, setPayProcess } = useContext(AppContext);
    const history = useHistory();
    let orderStatus = useRef(paymentStatus);



    const postInfo = _.cloneDeep(product);

    if (postInfo?.deliveryLocationType === "SHERIFF_OFFICE") {
        postInfo.sheriffOfficeLocation = JSON.parse(postInfo.sheriffOfficeLocation);
    } else if (postInfo?.deliveryLocationType === "FFL") {
        postInfo.fflStoreLocation = JSON.parse(postInfo.fflStoreLocation);
    }


    useEffect(() => {
        orderStatus.current = paymentStatus;
    }, [paymentStatus])

    return (<Fragment>
        <Modal {...{ show, setShow, hideCloseIcon }} className="payment-modal-iframe">
            <div className="d-flex flex-column align-items-center justify-content-center">
                {
                    orderStatus.current ? <Fragment>
                        <div className="py10">
                            <img src={bgCheckmarkIcon} alt="ordered" />
                        </div>
                        <h2 className="congrats">Congratulations</h2>
                        <div className="">
                            <div className="row bg-white bg-none justify-content-between">
                                <div className="col-lg-12">
                                    <div className="">
                                        <div className="pro-description">
                                            <div className="d-flex jcc py5">You have successfully placed your order.</div>
                                            <div className="d-flex jcc py5">You can pick up your product from your chosen location.</div>
                                            <div className="d-flex jcc py5">
                                                <u> {!_.isEmpty(postInfo) && postInfo.deliveryLocationType === "SHERIFF_OFFICE" && <span>&nbsp;Sheriff Office : {postInfo.sheriffOfficeLocation.freeformAddress}.&nbsp;</span>}
                                                    {!_.isEmpty(postInfo) && postInfo.deliveryLocationType === "OTHER_LOCATION" || postInfo.anyOtherLocations === "OTHER_LOCATION" && <span>&nbsp;Other : {postInfo?.anyOtherLocations?.formatted_address || postInfo?.anyOtherLocations?.name || ""}.&nbsp;</span>}
                                                    {!_.isEmpty(postInfo) && postInfo.deliveryLocationType === "FFL" && <span>&nbsp;FFL Store : {postInfo.fflStoreLocation.storeName || postInfo.fflStoreLocation.licHolderName || postInfo.fflStoreLocation.fflStoreName || postInfo.fflStoreLocation.name}, {postInfo.fflStoreLocation.premCity || postInfo.fflStoreLocation.fflPremiseCity || postInfo.fflStoreLocation.premiseCity}, {postInfo.fflStoreLocation.premState || postInfo.fflStoreLocation.fflPremiseState || postInfo.fflStoreLocation.premiseState}, {postInfo.fflStoreLocation.premZipCode || postInfo.fflStoreLocation.fflPremiseZipCode || postInfo.fflStoreLocation.premiseZipCode}.&nbsp;</span>} </u>

                                            </div>

                                            <div className="d-flex jcc py5">You will be notified once the seller provides the pickup date.</div>
                                        </div>
                                    </div>

                                    {!_.isEmpty(listingUser) && listingUser?.appUserType === "INDIVIDUAL" &&
                                        <div className='pro-dtle-box'>
                                            <div>Contact Seller</div>
                                            <div className='c6f6f'>
                                                <span>Name :</span>
                                                <span className='px2'>{listingUser?.firstName}</span><span className='px2'>{listingUser?.lastName}</span>
                                            </div>
                                            <div className='c6f6f'>
                                                <span>E-Mail :</span>
                                                <span className='px2'>{listingUser?.email}</span>
                                            </div>
                                            <div className='c6f6f'>
                                                <span>Phone :</span>
                                                <span className='px2'>{listingUser?.phoneNumber}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <input type="button" name="make_payment" class="next dark-button" value="Go to Home" onClick={()=>{setShowConfirmMsg(false); history.push("/")}} />
                        </div>
                    </Fragment>
                        : <Fragment>
                            <div class="py10">
                                <img src={bgFailureIcon} alt="ordered" style={{ width: "40px" }} />
                            </div>
                            <h2 class="congrats">Transaction Failed</h2>
                            <div class="col-lg-7">
                                <div class="row bg-white bg-none justify-content-between">
                                    <div class="col-lg-12">
                                        <div class="pro-dtle-box">
                                            <div className="d-flex jcc py5">Your order in draft.</div>
                                            <div className="d-flex jcc py5">Try again and complete transactions</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <input type="button" name="make_payment" class="next dark-button" value="Try Again" onClick={() => { setShowConfirmMsg(false) }} />
                            </div>
                        </Fragment>
                }
            </div>
        </Modal>
    </Fragment>);
}

export default OrderPlacedModal;