import React, { Fragment, useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../../../Shared/Modal";
import GLOBAL_CONSTANTS from "../../../../Constants/GlobalConstants";
import useToast from "../../../../commons/ToastHook";
import Spinner from "rct-tpt-spnr";
import $ from "jquery";
import ApiService from "../../../../services/api.service";
import { AppContext } from "../../../../contexts/AppContext";
import { useAuthState } from "../../../../contexts/AuthContext";
import { NOTIFICATION_CONSTANTS } from "../../Notification/Constants/NotificationConstants";
import _ from "lodash";
import './PaymentGateway.css'

const PaymentGatewayModal = ({
    show,
    setShow,
    setPaymentModalShow,
    hideCloseIcon = true,
    token,
    setToken,
    fromWhere = '',
    product,
    setTab,
    continuePay,
    setContinuePay,
    setShowConfirmMsg,
    isCounterTrade,
    setIsCounterTrade,
    selectedOrder,
    counterInfo,
    setCounterInfo,
    counterOfferAmount,
    directAccept,
    setDirectAccept,
    goToNextAfterSuccess = () => { },
    getUnclearedPayment = () => { },
    getPaymentHistory = () => { },
    cancelAction = () =>{ },
    counterTradeOfferSeller = ()=>{},
    tradeAcceptSeller = () =>{},
}) => {

    const userDetails = useAuthState();
    const Toast = useToast();
    const spinner = useContext(Spinner);
    const { paymentStatus, setPaymentStatus, orderDetails, setOrderDetails, isTxnFailed, setTxnFailed, setPayProcess } = useContext(AppContext);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);

    const openPopup = (token) => {
        try {
            var form = document.forms["formAuthorizeNetPopup"];
            document.getElementById("token").value = token;
            form.action = GLOBAL_CONSTANTS.PAYMENT.PAYMENT_API_URL;
            form.submit();

            var paymentPanel = document.getElementById("payment_frame");
            // paymentPanel.style.width = "450px";
            // paymentPanel.style.height = "580px";
            setIsLoading(false)
        } catch (err) {
            console.error("Exception occurred in openPopup --- ", err);
        }
    }

    // this method trigger to toggle listing if user cancelling order
    const toggleListing = (sid, disable = true) => {
        spinner.show("Please wait...");
        let payload = {
            "listingSids": [sid],
            "toggle": disable,
        };
        ApiService.isOfferedForTrade(payload)
            .then(
                (response) => { },
                (err) => { }
            )
            .finally(() => {
                spinner.hide();
            });
    };

    const getPaymentResponses = () => {
        try {
            if (!window.AuthorizeNetIFrame) window.AuthorizeNetIFrame = {};
            window.AuthorizeNetIFrame.onReceiveCommunication = function (querystr) {

                var params = parseQueryString(querystr);
                switch (params["action"]) {
                    case "successfulSave":
                        break;
                    case "cancel":
                        if(isCounterTrade || directAccept){
                            setToken(null);
                            setShow(false);
                            setDirectAccept(false);
                            setIsCounterTrade(false);
                            setPaymentModalShow(false);
                            Toast.warning({ message: "You canceled your transaction, Try again."});

                        }else{
                            setToken(null);
                            setShow(false);
                            setPayProcess(false);
                            if(product?.trade === true){
                                cancelAction()
                            }
                            Toast.warning({ message: continuePay ? "You canceled your transaction, Try again." : fromWhere !== "DEALER" ? "You canceled your transaction, so please check My Transactions" : "You canceled your transaction, Try again." });
                            fromWhere !== "DEALER" && setTxnFailed(true);
                            continuePay && setPaymentModalShow(false);
                        }
                        
                        break;
                    case "resizeWindow":
                        var w = parseInt(params["width"]);
                        var h = parseInt(params["height"]);
                        var paymentPanel = document.getElementById("payment_frame");
                        break;
                    case "transactResponse":
                        var result = JSON.parse(params['response'])
                        if (isCounterTrade || directAccept) {
                            tradeTransactionStatus(result);
                        } else {
                            if (fromWhere === "DEALER") {
                                dealerTransactionStatus(result);
                            } else {
                                transactionStatus(result);
                            }
                        }
                        
                }
            };

            function parseQueryString(str) {
                var vars = [];
                var arr = str.split('&');
                var pair;
                for (var i = 0; i < arr.length; i++) {
                    pair = arr[i].split('=');
                    vars.push(pair[0]);
                    vars[pair[0]] = unescape(pair[1]);
                }
                return vars;
            }
        } catch (err) {
        }
    }

    const dealerTransactionStatus = async (obj) => {
        try {
            let trxValue = obj;
            if(!_.isEmpty(trxValue)){

                if (trxValue?.responseCode === "1") {
                    let trxStatus = await ApiService.saveDealerTransaction(userDetails?.user?.sid);
                    Toast.success({ message: "Your transaction was completed successfully." })

                }else{
                    Toast.error({ message: "May be your transaction is failed, If amount is debited please contact to your admin" })
                }
                setToken(null);
                setShow(false);
                setPayProcess(false);
                getUnclearedPayment();
                getPaymentHistory();
                continuePay && setPaymentModalShow(false);
                

            }else{
                Toast.error({ message: "May be your transaction is failed, If amount is debited please contact to your admin"})
                setToken(null);
                setShow(false);
                setPayProcess(false);
                getUnclearedPayment();
                getPaymentHistory();
                continuePay && setPaymentModalShow(false);

            }
            

        } catch (err) {
            console.error("Exception occurred in dealerTransactionStatus --- ", err);
        }
    }

    const transactionStatus = async (obj) => {
        try {
            let trxValue = obj;
            let transactionStatus = trxValue?.responseCode === "1" ? "SUCCESS" : "FAILED";
            let payStatus = trxValue?.responseCode === "1" ? true : false;
            setPaymentStatus(payStatus);
            setTxnFailed(!payStatus);

            let trxPayload = {
                "accountNumber": trxValue?.accountNumber,
                "accountType": trxValue?.accountType,
                "authorizationCode": trxValue?.authorization,
                "dateTime": trxValue?.dateTime,
                "merchantName": trxValue?.merchantName,
                "orderSid": continuePay ? product?.orderSid : orderDetails.data.sid,
                "responseCode": trxValue?.responseCode,
                "totalAmount": trxValue?.totalAmount,
                "transactionId": trxValue?.transId,
                "transactionStatus": transactionStatus,
                "creditorSid": product?.appUsersSid,
                "creditorType": product?.listingType
            };

            let orderPayload = {
                "listingDetailsSid": continuePay ? product?.orderSid : product?.sid,
                "orderSid": continuePay ? product?.orderSid : orderDetails.data.sid,
                "paymentStatus": transactionStatus
            }

            let trxStatus = await ApiService.setupPaymentTransaction(trxPayload);
            let orderStatusChange = await ApiService.changeOrderStatus(orderPayload);

            !continuePay && goToNextAfterSuccess(orderDetails);
            continuePay && setShowConfirmMsg(true);
            
            if ((product?.buyType === "TRADE" && continuePay) && payStatus === true) {
                toggleListing(product?.tradeOfferListingSid);
            }

            if (product?.trade === true && !payStatus) {
                toggleListing(product?.sid, false);
            }

            setToken(null);
            setShow(false);
            setPayProcess(false);
            if(payStatus){
                updateNotification(continuePay ? product?.buyType === "TRADE" ? product?.yourProductListingSid  : product?.sid : history.location.state.notifId);
            }
            setContinuePay(false);
            continuePay && setPaymentModalShow(false)


        } catch (err) {
            console.error("Exception occurred in transactionStatus --- ", err);
        }
    }
    const tradeTransactionStatus = async(obj) =>{
        try{
            let trxValue = obj;
            let transactionStatus = trxValue?.responseCode === "1" ? "SUCCESS" : "FAILED";
            let payStatus = trxValue?.responseCode === "1" ? true : false;
            setPaymentStatus(payStatus);
            
            let trxPayload = {
                "accountNumber": trxValue?.accountNumber,
                "accountType": trxValue?.accountType,
                "authorizationCode": trxValue?.authorization,
                "dateTime": trxValue?.dateTime,
                "merchantName": trxValue?.merchantName,
                "orderSid": fromWhere === "NOTIFICATION" ? selectedOrder?.notificationJson?.orderDetailsSid : selectedOrder?.orderDetailsSid,
                "responseCode": trxValue?.responseCode,
                "totalAmount": trxValue?.totalAmount,
                "transactionId": trxValue?.transId,
                "transactionStatus": transactionStatus,
                "creditorSid": fromWhere === "NOTIFICATION" ? selectedOrder?.notificationJson?.appUsersSid : selectedOrder?.appUsersSid,
                "creditorType": fromWhere === "NOTIFICATION" ? selectedOrder?.notificationJson?.listingType : selectedOrder?.listingType
            };

            let counterTradePayload ={
                "appUserType": fromWhere === "NOTIFICATION" ? selectedOrder?.notificationJson?.type : selectedOrder?.userType,
                "appUsersSid": fromWhere === "NOTIFICATION" ? selectedOrder?.notificationJson?.placedBySid : selectedOrder?.placedByAppUsersSid,
                "counterValue": counterOfferAmount,
                "orderHasListingSid": fromWhere === "NOTIFICATION" ? selectedOrder?.notificationJson?.ohl : selectedOrder?.notificationJson?.ohl,
                "paymentStatus": transactionStatus,
              }

            let voidPayload = {
                "orderSid": fromWhere === "NOTIFICATION" ? selectedOrder?.notificationJson?.orderDetailsSid : selectedOrder?.orderDetailsSid,
                "sellerSid": fromWhere === "NOTIFICATION" ? selectedOrder?.notificationJson?.appUsersSid : selectedOrder?.appUsersSid,
                "sellerType": fromWhere === "NOTIFICATION" ? selectedOrder?.notificationJson?.listingType : selectedOrder?.listingType

            }
            if (payStatus === true) {
                let tradeVoid = await ApiService.saveTradeVoid(voidPayload);
                let saveTransactions = await ApiService.setupPaymentTransaction(trxPayload);
                let finalCall = directAccept === true ? 
                tradeAcceptSeller(NOTIFICATION_CONSTANTS.STATUS.ACCEPTED, selectedOrder.notificationJson.ohl, selectedOrder?.notificationJson?.sid, NOTIFICATION_CONSTANTS.USER_TYPE.BUYER) :
                counterTradeOfferSeller(counterTradePayload, counterInfo)

            //   await ApiService.saveTradeVoid(voidPayload).then(
            //         response => {
            //             savePaymentTransaction(trxPayload)
            //             if(directAccept === true){
            //                 tradeAcceptSeller(NOTIFICATION_CONSTANTS.STATUS.ACCEPTED, selectedOrder.notificationJson.ohl, selectedOrder?.notificationJson?.sid, NOTIFICATION_CONSTANTS.USER_TYPE.BUYER)
            //             }else{
            //                 counterTradeOfferSeller(counterTradePayload, counterInfo)
            //             }
            //         },
            //         err => {
            //             spinner.hide();
            //             Toast.error({ message: err.response.data && (err.response.data.error || err.response.data.message), time: 3000 });
            //         }
            //     );
            } else {
                Toast.error({ message: "Your transaction is failed, Try again.", time: 3000 });
            }
            
            

            setToken(null);
            setShow(false);
            setIsCounterTrade(false);
            setDirectAccept(false);
            setPaymentModalShow(false);
            

        }catch (err) {
            console.error("Exception occurred in tradeTransactionStatus --- ", err);
            setToken(null);
            setShow(false);
            setIsCounterTrade(false);
            setDirectAccept(false);
            setPaymentModalShow(false);
        }
    }

    const savePaymentTransaction =(payload)=>{
        try{
            ApiService.setupPaymentTransaction(payload);
        }catch(err) {

        }
    }

    /**  this method is update notification status
     * @param {String} ohl = order has listing sid
     */
    const updateNotification = (sid) => {
        try {
            if (sid) {
                ApiService.updateNotification(sid).then(
                    (response) => {
                    },
                    (err) => {
                        console.error("error occur on updateNotification()", err);
                    }
                );
            }
        } catch (err) {
            console.error("error occur on updateNotification()", err);
        }
    };


    useEffect(() => {
        token && openPopup(token)
    }, [token]);

    useEffect(() => {
        getPaymentResponses();
    }, [])

    return (<Fragment>
        <Modal {...{ show, setShow, hideCloseIcon }} className="payment-modal-iframe">
            <div className="jcc-c iframe-modal">
                <div className="jcc iframe-header">
                    GUNTRADERZ
                </div>
                <div id="iframe_holder" className="center-block jcc">
                    { isLoading && <div className="loader"></div> }
                    <iframe id="payment_frame" className="embed-responsive-item panel pmt-iframe" name="payment_frame" style={{"display": isLoading ? "none" : "block", "width": "450px", "height": "580px"}}>
                        <div>Hello</div>
                    </iframe>
                </div>
                <div className="jcc iframe-bottom">
                    Powered by GUNTRADERZ
                </div>
            </div>

            <form id="formAuthorizeNetPopup" action="" method="post" target="payment_frame" >
                <input type="hidden" id="token" name="token" value="" />
            </form>
        </Modal>
    </Fragment>);
}

export default PaymentGatewayModal;