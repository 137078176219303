import { useState, useContext, useEffect, useRef } from 'react'
import ApiService from '../../../services/api.service';
import Spinner from "rct-tpt-spnr";
import useToast from '../../../commons/ToastHook';
import _ from 'lodash';
import { Formik, Field, ErrorMessage } from "formik";
import { Form } from 'react-bootstrap';
import { AppContext } from '../../../contexts/AppContext';
import { useAuthState } from '../../../contexts/AuthContext';
import { IoMdCloseCircleOutline } from "react-icons/io";
import CustomDropdown from '../../Shared/CustomDropdown/CustomDropdown';
import UnclearedPayment from '../../Profile/MyPayments/UnclearedPayment';



const AdminPayment = () => {
    const Toast = useToast();
    const spinner = useContext(Spinner);
    const { setPlatformVariables } = useContext(AppContext)
    const userDetails = useAuthState();
    const [dealerSid, setDealerSid] = useState(null);
    const [dealerList, setDealerList] = useState(null);
    const [dealerName, setDealerName] = useState(null);
    const [listingStatus, setListingStatus] = useState(null);



    // This is get all the dealer list.
    const getAllDealerList = () => {
        try {
            ApiService.getAllDealerList(userDetails?.user?.sid).then(
                response => {
                    setDealerList(response.data);
                    setDealerName(response.data?.[0]?.firstName);
                },
                err => {
                    console.error("Error occurred in getAllDealerList() --", err);

                }
            ).finally(() => {
            });
        } catch (err) {
            console.error("Error occur when getAllDealerList--", err);
        }
    }


    useEffect(() => {
        if (userDetails?.user?.sid) {
            getAllDealerList();
        }
    }, [userDetails]);

    useEffect(() => {
        if (!_.isEmpty(dealerList)) {
            setDealerSid(dealerList?.[0]?.sid);
            setListingStatus(dealerList?.[0]?.listingsStatus);
        }
    }, [dealerList]);



    return <div className='flx'>
        <div className='flx1'>
            <Form.Group>
                <Form.Label class="p-0"><h5 class="label-head mb-0">Dealer Name</h5></Form.Label>
                <CustomDropdown {...{
                    data: (dealerList?.length && dealerList) || [],
                    bindKey: "firstName",
                    searchKeywords: "",
                    title: (dealerName || !_.isEmpty(dealerList) && dealerList?.[0].firstName) || "- Select dealer name -" ,
                    onSelect: (data) => {
                        setDealerSid(data?.sid);
                        setDealerName(data?.firstName);
                        setListingStatus(data?.listingsStatus);
                    },
                }} />
                <Form.Control.Feedback type="invalid">
                    {
                        _.isEmpty(dealerSid) && "Select Dealer name"
                    }
                </Form.Control.Feedback>
            </Form.Group>
        </div>
        <div className='flx3 px10'>
            {
                dealerSid ?
                    <UnclearedPayment {...{ dealerSid, listingStatus, setListingStatus, getAllDealerList }} />
                    : <div className='flx jcc py10 f20'>
                        You are not selected dealer name
                    </div>
            }

        </div>
    </div>
}

export default AdminPayment;