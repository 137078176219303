import React, { useContext, useState, useEffect, memo } from 'react'
import { Formik, Field } from "formik"
import _ from 'lodash';
import $ from 'jquery';
import Spinner from "rct-tpt-spnr";
import { Form, Row } from 'react-bootstrap';
import ApiService from "../../../services/api.service";
import { useAuthState } from '../../../contexts/AuthContext/context';
import { Link, useHistory } from 'react-router-dom'
import { goToTopOfWindow } from '../../../commons/utils';
import useToast from '../../../commons/ToastHook';
import PriceSummary from './../PriceSummary';
import { AppContext } from '../../../contexts/AppContext';

const PaymentsView = ({ orderBy, setTab, product, valueToMatch, tabWiseData }) => {
    const history = useHistory();
    const Toast = useToast();
    const spinner = useContext(Spinner);
    // const initialValues = {
    //     card: tabWiseData?.paymentCard?.sid ? tabWiseData?.paymentCard?.sid : ""
    // };

    const [myCards, setMyCards] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const userDetails = useAuthState();
    const { platformVariables } = useContext(AppContext);

    const [number, SetNumber] = useState("");
    const [name, SetName] = useState("");
    const [month, SetMonth] = useState("");
    const [expiry, SetExpiry] = useState("");
    const [cvc, SetCvc] = useState("");
    const [focus, SetFocus] = useState("");
    const [status, setStatus] = useState(false);

    const handleDate = (e) => {
        SetMonth(e.target.value);
        // SetExpiry(e.target.value);
    };
    const handleExpiry = (e) => {
        SetExpiry(month.concat(e.target.value));
    };

    useEffect(() => {
        spinner.show("Please wait...");
        ApiService.myCards(userDetails.user.sid).then(
            response => {
                setMyCards(response.data);
            },
            err => {
                Toast.error({ message: err.response && err.response.data ? (err.response.data.message || err.response.data.error || err.response.data.status) : 'Internal server error! Please try after sometime.', time: 2000 });
            }
        ).finally(() => {
            spinner.hide();
            setIsDataLoaded(true);
        });
    }, []);

    const onNextStep = async (values) => {
        let isListingActive = await ApiService.validateActiveListing(product.sid);
        if (isListingActive.data) {
            // setSubmitting(false);
            goToTopOfWindow();
            // tabWiseData.paymentCard = _.filter(myCards, { sid: values.card })[0];
            $('#location').addClass('active');
            setTab('location');
        } else {
            history.push("/");
            Toast.warning({ message: 'Sorry! This item is currently sold out!', time: 2000 });
        }
    }

    const initIsOfferedForTrade = (list = [], disable = true) => {
        spinner.show("Please wait...");
        let payload = {
            "listingSids": list.map(r => r.sid),
            "toggle": disable
        };
        ApiService.isOfferedForTrade(payload).then(
            response => { },
            err => { }
        ).finally(() => {
            spinner.hide();
        });
    }

    const cancelAction = () => {
        if (tabWiseData.tradeListItems && tabWiseData.tradeListItems.length > 0) initIsOfferedForTrade(tabWiseData.tradeListItems, false);
        tabWiseData.tradeListItems = [];
        history.replace('/');
        goToTopOfWindow();
    }

    // Calculate the total amount to paid
    const totalAmount = () => {
        let totalAmounts = ''
        try {
            let tax = platformVariables.tax ? (product?.sellPrice * platformVariables.tax) / 100 : 0;
            totalAmounts = platFormFeeCalculate() + tax;
        }
        catch (err) {
            console.error("error occur on")
        }
        return totalAmounts = totalAmounts ? Number(totalAmounts).toFixed(2) : ""
    }

    // calculate the total amount of the platform.
    const platFormFeeCalculate = () => {
        let platformFee = ''
        try {
            let fee = platformVariables.platFormBuyerFee.percentage ? (product?.sellPrice * platformVariables.platFormBuyerFee.percentage) / 100 : 0;
            platformFee = fee > platformVariables.platFormBuyerFee.amount ? fee : platformVariables.platFormBuyerFee.amount
        } catch (err) {
            console.error("error occur on platFormFeeCalculate()", err)
        }
        return platformFee
    }

    return (
        <>
            <div>
                <div className="mb-8">
                    <h2 class="card-title-header create-listing-block mobile-off">Payment Info</h2>
                    <h2 class="desktop-off create-listing-block">Payment Info</h2>
                </div>
                {orderBy === 'buy' && <PriceSummary {...{ price: tabWiseData.carts.subTotal, product, tabWiseData }} />}
                {orderBy === 'bid' && <PriceSummary {...{ price: tabWiseData.bidInfo.bidValue, product, tabWiseData }} />}
                {orderBy === 'trade' && <PriceSummary {...{ tabWiseData, product, price: (valueToMatch.isPayBalance && valueToMatch.amount) || 0 }} />}

                {
                    product?.listingType === "INDIVIDUAL" &&
                    <div className="mb-8">
                        <h3 class="attention-header">ATTENTION!</h3>
                        <div className='column'>
                            <div className=''>
                                <p className="mobile-off">Note : We are collecting platform fees & tax amounts only. It's not returnable. The remaining amount should be pay the seller.</p>
                                <p className="mb-2 des-txt-color des-txt-aligin desktop-off">Note : We are collecting platform fees & tax amounts only. It's not returnable. The remaining amount should be pay the seller.</p>
                            </div>
                            <div className="pro-price jcb my-1 pr-0">
                                <div>Total Payable</div>
                                <div>$ {totalAmount()}</div>
                            </div>
                        </div>

                        {/* <p className="description-txt mb-2 des-txt-color mobile-off">Select the card from which you want the amount to be debited</p>
                        <p className="description-txt des-txt-size mobile-off">Note : Incase the transaction is a trade or bid, the amount will be deducted once the trade or bid is accepted.</p>
                        <p className="description-txt mb-2 des-txt-color des-txt-aligin desktop-off">Select the card from which you want the amount to be debited</p>
                        <p className="description-txt des-txt-size des-txt-aligin desktop-off">Note : Incase the transaction is a trade or bid, the amount will be deducted once the trade or bid is accepted.</p> */}

                    </div>
                }


                <div class="text-right mobile-off">
                    {/* <input type="button" name="Add Card" class="cancel-btn mt-1 " value={status === true ? "Remove" : "Add"} onClick={() => {
                                                            setStatus(!status);
                                                            SetNumber("");
                                                            SetName("");
                                                            SetMonth("");
                                                            SetExpiry("");
                                                            SetCvc("")
                                                        }
                                                        } /> */}

                    <div className="media-body ml-3 payment">

                        {/* TODO: Later */}
                        {/* <div className="mt-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setStatus(!status);
                                SetNumber("");
                                SetName("");
                                SetMonth("");
                                SetExpiry("");
                                SetCvc("")
                            }
                            }

                        ><span>+</span> &nbsp;&nbsp;Add New Card</div> */}

                        {/* TODO: Later */}

                        {/* <input type="radio"  value={status === true ? "Remove" : "Add"} onClick={() => {
                                                            setStatus(!status);
                                                            SetNumber("");
                                                            SetName("");
                                                            SetMonth("");
                                                            SetExpiry("");
                                                            SetCvc("")
                                                        }
                                                        } /> */}
                    </div >


                </div>
                <div className="pro-passChange-ctn mt-4">

                    <div class="text-right mobile-off">

                        {/* <input type="button" name="Add Card" class="cancel-btn mt-2" value={status === true ? "Remove Card" : "Add Card"} onClick={() => {
    setStatus(!status);
    SetNumber("");
    SetName("");
    SetMonth("");
    SetExpiry("");
    SetCvc("");
}
} /> */}
                        <input type="button" name="cancel" class="cancel-btn mt-2" value="Cancel" onClick={()=> cancelAction()} />
                        <input
                            onClick={()=>{onNextStep()}}
                            // disabled={(!values.card || (!valueToMatch.offerStatus && orderBy === "trade")) && !(number.length > 0 && name.length > 0 && month.length > 0 && expiry.length > 0 && cvc.length === 3)} // TODO: Later
                            type="button"
                            name="next"
                            class="next action-button nextBtn"
                            value="Continue"
                        />


                    </div>
                    <section class="mobile-btn-section desktop-off">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="proPg-btnArea">
                                        <div className="proPg-btnArea-div-outer">
                                            {/* TODO: Later */}
                                            {/* <div className="proPg-btnArea-div-inner">

                        <input type="button" value={status === true ? "Remove Card" : "Add Card"} onClick={() => {
                            setStatus(!status);
                            SetNumber("");
                            SetName("");
                            SetMonth("");
                            SetExpiry("");
                            SetCvc("");
                        }} class="submt-btn submt-btn-lignt mr10 text-center full-w" />
                    </div> */}

                                            <div className="proPg-btnArea-div-inner">
                                                <input type="button" value="Cancel" onClick={()=> cancelAction()} class="submt-btn submt-btn-lignt mr10 text-center full-w" />
                                            </div>
                                            <div className="proPg-btnArea-div-inner">
                                                <input
                                                    type="button"
                                                    value="Continue"
                                                    onClick={() => { onNextStep() }}
                                                    // disabled={(!values.card || (!valueToMatch.offerStatus && orderBy === "trade")) && !(number.length > 0 && name.length > 0 && month.length > 0 && expiry.length > 0 && cvc.length === 3)} // TODO: Later
                                                    class="submt-btn submt-btn-dark text-center full-w"
                                                />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>





                    {/* <form>
                        <div className="row">
                            <div className="col-sm-11">
                                <label for="name">Card Number</label>
                                <input
                                    type="tel"
                                    className="form-control"
                                    value={number}
                                    name="number"
                                    maxlength="16"
                                    pattern="[0-9]+"
                                    onChange={(e) => {
                                        SetNumber(e.target.value);
                                    }}
                                // onFocus={(e) => SetFocus(e.target.name)}
                                ></input>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-sm-11">
                                <label for="name">Card Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    name="name"
                                    onChange={(e) => {
                                        SetName(e.target.value);
                                    }}
                                // onFocus={(e) => SetFocus(e.target.name)}
                                ></input>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div
                                className="col=sm-8"
                                style={{
                                    ...{ "padding-right": "12em" },
                                    ...{ "padding-left": "1em" }
                                }}
                            >
                                <label for="month">Expiration Date</label>
                            </div>
                            <div className="col=sm-2">
                                <label for="cvv">CVV</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-2">
                                <select
                                    className="form-control"
                                    name="expiry"
                                    onChange={handleDate}
                                >
                                    <option value=" ">Month</option>
                                    <option value="01">Jan</option>
                                    <option value="02">Feb</option>
                                    <option value="03">Mar</option>
                                    <option value="04">April</option>
                                    <option value="05">May</option>
                                    <option value="06">June</option>
                                    <option value="07">July</option>
                                    <option value="08">Aug</option>
                                    <option value="09">Sep</option>
                                    <option value="10">Oct</option>
                                    <option value="11">Nov</option>
                                    <option value="12">Dec</option>
                                </select>
                            </div>
                            &nbsp;
                            <div className="col-sm-2">
                                <select
                                    className="form-control"
                                    name="expiry"
                                    onChange={handleExpiry}
                                >
                                    <option value=" ">Year</option>
                                    <option value="21">2021</option>
                                    <option value="22">2022</option>
                                    <option value="23">2023</option>
                                    <option value="24">2024</option>
                                    <option value="25">2025</option>
                                    <option value="26">2026</option>
                                    <option value="27">2027</option>
                                    <option value="28">2028</option>
                                    <option value="29">2029</option>
                                    <option value="30">2030</option>
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <input
                                    type="tel"
                                    name="cvc"
                                    maxlength="3"
                                    className=" form-control"
                                    value={cvc}
                                    pattern="\d*"
                                    onChange={(e) => {
                                        SetCvc(e.target.value);
                                    }}
                                    onFocus={(e) => SetFocus(e.target.name)}
                                ></input>
                            </div>
                        </div>
                        <br />
                        <input
                            type="submit"
                            className="btn btn-secondary form-control"
                            value="Submit"
                        />
                    </form> */}


                </div>
            </div>
        </>
    )
}

export default memo(PaymentsView);