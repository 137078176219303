import React from 'react';

const TradeTag = () => {
  return (
    <div class="trade-tag d-flex bg-dark text-white justify-content-center">
      <p>Trade With</p>
    </div>
  );
};
 
export default TradeTag;