import React, { useEffect, useState, useContext, memo, Fragment } from 'react';
import Spinner from "rct-tpt-spnr";
import ApiService from '../../../services/api.service';
import { useAuthState } from '../../../contexts/AuthContext/context';
import { Tab, Nav } from 'react-bootstrap';
import useToast from '../../../commons/ToastHook';
import UnclearedPayment from './UnclearedPayment';
import PaymentKeySetup from './PaymentKeySetup';

let unClearedDetails = {
    "preDue": "24 Hrs",
    "postDue": "12 Hrs",
    "totalAmount": 6000
}


const MyPayments = () => {
    const userDetails = useAuthState();
    const Toast = useToast();
    const spinner = useContext(Spinner);
    const [tabKey, setTabKey] = useState("payment-history");
    const [paymentKey, setPaymentKey] = useState(null);
    

    // Thi sis get the payment setup Information.
    const getPaymentSetup = () => {
        try {
            // let sid = "538EE1FBBAD1477FA8C9F54B92F50535D615E318290349E0B490C53016681CFB";

            ApiService.getPaymentSetup(userDetails?.user?.sid).then(
                response => {
                    setPaymentKey(response?.data);
                },
                err => {
                    console.error("Error occur when getOrderHistoryDetails--", err);
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("Error occur when getPaymentSetup--", err);
        }
    }

    // This is call the payment setup function
    useEffect(() => {
        if (userDetails?.user?.sid) {
            getPaymentSetup();
        }
    }, [userDetails])


    return <Fragment>
        {
            userDetails.user.appUserType === 'DEALER' &&
            <Tab.Container defaultActiveKey={tabKey} onSelect={k => setTabKey(k)}>
                <Nav className="nav-tabs myac-tab-head" id="myTab" >
                    <Nav.Item>
                        <Nav.Link eventKey={"payment-history"} id="payment-history">{"Uncleared Payment"}</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link eventKey={"payment-key"} id="payment-key">{"Payment Setup"}</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content className="myAc-TabContent">
                    <Tab.Pane eventKey={"payment-history"} id="payment-history-tab">
                        <UnclearedPayment />
                    </Tab.Pane>
                    <Tab.Pane eventKey={"payment-key"} id="payment-key-tab">
                        <PaymentKeySetup {...{ paymentKey, setPaymentKey, getPaymentSetup, tabKey }} />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        }
    </Fragment>
}

export default memo(MyPayments);