import { useState, useEffect } from 'react';
import Modal from '../Modal';
import { NOTIFICATION_CONSTANTS } from '../../Profile/Notification/Constants/NotificationConstants';
import './term.css'

let TermAndConditions = <>
    <div className='termsPanel'>
        <div className='flx jcc font-weight-bold'>
            TERMS AND CONDITIONS – USER AGREEMENT
        </div>
        <div className=''>
            <p className=''>
                PLEASE READ THIS TERMS AND CONDITIONS AGREEMENT CAREFULLY BEFORE USING THE
                GUNTRADERZ.COM WEBSITE (Site, Platform, We, Us, Our). THIS IS A LEGALLY BINDING AGREEMENT.
            </p>
            <p className=''>
                By using the Site, You (as an User of the Site as a Seller or Buyer) agree to the terms and conditions in
                this agreement. If you do not agree to any of the terms and conditions in this agreement, <span>please do not
                    use this site.</span>
            </p>
            <p className=''>
                BY PURCHASING FROM THE SITE, YOU GUARANTEE AND UNDERTAKE THAT YOU WILL CHECK ALL
                APPLICABLE LOCAL, STATE, FEDERAL LAWS AND STAY COMPLIANT WITH ALL SUCH LAWS THAT ARE
                APPLICABLE TO YOU.
            </p>

            <p className=''>
                DO NOT CHECK ‘AGREE’ UNLESS YOU AGREE TO ALL OF THE TERMS AND CONDITIONS IN THIS
                AGREEMENT.
            </p>

        </div>
        <div className=''>
            <div className=''>
                <div className=''>
                    1. INTRODUCTION
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        1.1.
                    </div>
                    <div className='pl5'>
                        Guntraderz.com (Site, Platform, We used interchangeably) is a web platform which brings
                        buyers and sellers together. Site is not a party to any of the transactions on Platform. Site is an
                        advertising medium and nothing more. While Site makes reasonable efforts to do basic
                        checking of users on the Platform, it is your sole responsibility to ensure the legality of all the
                        items you sell / buy through the platform and to who you sell it to or buy from.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        1.2.
                    </div>
                    <div className='pl5'>
                        By continuing to use the Site, you agree to all the terms and conditions in this agreement along
                        with the Privacy policy and any other rules / policies published from time to time on the Site.
                        Do not use this Site if you do not agree with any of the rules and conditions for use of this Site.
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    2. ACKNOWLEDGEMENT
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        2.1.
                    </div>
                    <div className='pl5'>
                        By using the Site user acknowledges that guntraderz.com is the platform operated by
                        Guntraderz LLC a Delaware LLC.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        2.2.
                    </div>
                    <div className='pl5'>
                        Guntraderz.com and Guntraderz LLC are used interchangeably and part of same entity.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        2.3.
                    </div>
                    <div className='pl5'>
                        User acknowledges that there could be other associated platforms operated by Guntraderz LLC
                        and usage of other associated platforms is also governed by this terms and conditions.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        2.4.
                    </div>
                    <div className='pl5'>
                        User further acknowledges that any fees or other charges associated with the purchase of
                        products will show on their credit card or other forms of payment, receiving (if selling) modes
                        will show the name ‘Guntraderz LLC’.
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    3. REPRESENTATION By registering and using the Platform you agree and represent that:
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.1.
                    </div>
                    <div className='pl5'>
                        You are 21 years or older and legally able to sell or purchase firearms or other products listed
                        on the Platform.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.2.
                    </div>
                    <div className=''>
                        You will not let others use your Userid and Password or share your account details with others.
                        If you suspect or become aware your Userid or being used in an unauthorized manner, you will
                        contact us immediately.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.3.
                    </div>
                    <div className='pl5'>
                        You will not use guntraderz.com for any illegal activity.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.4.
                    </div>
                    <div className='pl5'>
                        You are responsible to comply with all applicable Federal, State, Local, Tribal, Presidential
                        executive orders and all applicable licensing regulations.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.5.
                    </div>
                    <div className='pl5'>
                        You will help in keeping the site safe and open by providing peer reviews.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.6.
                    </div>
                    <div className='pl5'>
                        You take full responsibility for all the actions you take and any subsequent consequences
                        related to your use of guntraderz.com.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.7.
                    </div>
                    <div className='pl5'>
                        ou will contact Bureau of Alcohol, Tobacco, Firearms, and Explosive (ATF) and / or visit the ATF
                        website at http://www.atf.gov if unsure about any rules or regulations related to firearms or
                        other products regulated by ATF.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.8.
                    </div>
                    <div className='pl5'>
                        You understand that all information that you upload to the Platform, including but not limited
                        to Photos, Text, Graphics and all other information is your sole responsibility. Guntraderz.com
                        does not control the information posted to the Site and is not responsible for any information
                        posted on the Platform. You also understand that you may be exposed to content that could
                        be offensive, indecent or objectionable.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.9.
                    </div>
                    <div className='pl5'>
                        Guntraderz.com does not guarantee the accuracy, legality, quality of any information posted to
                        the Platform.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.10.
                    </div>
                    <div className='pl5'>
                        Under no circumstances Guntraderz.com or Guntraderz LLC are liable for any errors or
                        omissions in content, any loss or damage of any kind as a result of usage of content on the Site
                        whether posted, emailed or transmitted otherwise.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.11.
                    </div>
                    <div className='pl5'>
                        Guntraderz LLC can change these terms at any time without notification. You are solely
                        responsible for reading the most current version of the terms and conditions as applicable at
                        the time of any and all activity conducted by You on the Site.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.12.
                    </div>
                    <div className='pl5'>
                        You will not list or trade in any prohibited items including any animals.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.13.
                    </div>
                    <div className='pl5'>
                        You will not create more than one account, list any item only once and at the location of
                        your legal residence.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.14.
                    </div>
                    <div className='pl5'>
                        You will abide by all the terms and conditions and any other policies as published on the
                        Site.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        3.15.
                    </div>
                    <div className='pl5'>
                        Guntraderz.com can remove or edit any information including your listings on the Site
                        without notification.
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    4. PROHIBITED CONTENT POSTING GUIDELINES
                </div>
                <p className='ml10'>
                    These are general Prohibited Content guidelines for listings or any user forums posts. These are not
                    all inclusive. Posting any kind of objectionable (decided at the sole discretion of Platform) material
                    by any means on the Platform is strictly prohibited.
                </p>
                <p className='ml10'>
                    We reserve the right to remove or edit any listing or post without any notice for any reason. Other
                    actions include but not limited to permanent removal of the offending userid(s) at the sole
                    discretion of Platform.
                </p>
                <p className='ml10'>
                    You agree to not use the Platform to make available by any means, all types of content mentioned
                    below (not all inclusive) or of content similar in nature.
                </p>
                <div className='flx ml10'>
                    <div className=''>
                        4.1.
                    </div>
                    <div className='pl5'>
                        PORNOGRAPHIC CONTENT Uploading, posting, emailing, transmitting or otherwise making any
                        kind of Pornographic material available on the Platform by any means
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        4.2.
                    </div>
                    <div className='pl5'>
                        HARMFUL CONTENT Uploading, posting, emailing, transmitting or otherwise making any
                        content available on the Platform, that is harmful, abusive, harassing, obscene, vulgar, invasive
                        of another’s privacy, hateful, racially, ethnically or otherwise objectionable content directed
                        against another individual or groups of individuals.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        4.3.
                    </div>
                    <div className='pl5'>
                        HARMING MINORS Making available on the platform any form of material that harms minors in
                        any way is strictly prohibited. Such accounts will be permanently removed and information
                        made available to appropriate law enforcement agencies.
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    5. USER TERMINATION
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        5.1.
                    </div>
                    <div className='pl5'>
                        The Site can either suspend or remove any users from its Platform at its sole discretion.
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    6. SHIPPING AND RETURNS
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        6.1.
                    </div>
                    <div className='pl5'>
                        Shipping and Returns are governed by our Shipping and Returns policy found here and
                        incorporated herein by reference
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        6.2.
                    </div>
                    <div className='pl5'>
                        You attest that you have read the Shipping and Returns policy and agree to abide by the terms
                        in the Shipping and Returns policy
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    7. FEES
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        7.1.
                    </div>
                    <div className='pl5'>
                        ll fees related to use of the Platform are detailed in our Fee Schedule and is incorporated
                        herein by reference.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        7.2.
                    </div>
                    <div className='pl5'>
                        All fees are subject to change without any notice and is at the sole discretion of Us.
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    8. INTELLECTUAL PROPERTY
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        8.1.
                    </div>
                    <div className='pl5'>
                        Use of Your Content: For all materials that you upload or otherwise make available on the Site,
                        You grant Us a worldwide irrevocable, royalty free, perpetual license to use, modify, make
                        available on the Site and other affiliates, create derivatives, sell otherwise use or distribute.
                        This license, however, shall not apply to Us sharing with non-affiliated third parties, for which a
                        separate agreement will be needed
                    </div>
                </div>
                <div className='flx ml25'>
                    <div className=''>
                        8.1.1.
                    </div>
                    <div className='pl5'>
                        Any feedback, comments, posts in user forums, suggestions, ideas posted, offered to us
                        otherwise shall be and remain Our property. By Providing such content to Us You give us
                        all worldwide rights, interest and title, all copyrights and other Intellectual property rights
                        in perpetuity
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        8.2.
                    </div>
                    <div className='pl5'>
                        Use of Platform: Your use of the Platform does not grant you any ownership, rights, title or any
                        other interest. The Platform is solely for your personal use. You agree to not misuse the
                        Platform and not download, distribute, reverse engineer, create derivative works, exploit in any
                        way in whole or part. The Software used in creating the site is the exclusive property of
                        Guntraderz LLC and is protected by United States and International laws. Other than using the
                        functionality of the Site for personal use, Users are strictly prohibited from using the Platform
                        for any and all other purposes which are not related to using the functionality of the Platform
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        8.3.
                    </div>
                    <div className='pl5'>
                        Copyrights and Trademarks: Any third party trademarks that appear on the Platform whether
                        uploaded by Us or by Users of the Platform are owned by the respective owners, and may or
                        may not be affiliated with Us. Display of Guntraderz.com logo or other identifying materials on
                        other non-affiliated platform / medium of any nature is prohibited, without prior permission
                        from Guntraderz LLC
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    9. INDEMNIFICATION
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        9.1.
                    </div>
                    <div className='pl5'>
                        You agree to indemnify, defend and hold Guntraderz LLC and all of its affiliates harmless from
                        any claim, liability, loss, cost or expense (including reasonable attorneys’ fees) of any nature
                        whatsoever arising out of or in connection with the use of your Userid on Our Platform and / or
                        affiliates
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    10. GOVERNING LAW AND DISPUTES
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        10.1
                    </div>
                    <div className='pl5'>
                        Guntraderz LLC is a Delaware incorporated LLC
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        10.2
                    </div>
                    <div className='pl5'>
                        You agree that all matters related to your use of Our Platform, any disputes, claims
                        arising out of such use shall be governed by, construed and enforced in accordance with the
                        laws of State of Delaware
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    11. ARBITRATION
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        11.1.
                    </div>
                    <div className='pl5'>
                        To the fullest extent permitted by law, You hereby agree that any disputes or all matters
                        arising out of your use of the Site shall be settled through Mandatory Arbitration in the
                        Chancery of Delaware per laws of Delaware. You expressly waive any objection now or in the
                        future to the laying of the Venue.
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    12. OTHER PROVISIONS
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        12.1.
                    </div>
                    <div className='pl5'>
                        Complying with Law: You understand that while we fully support the Second
                        Amendment to the Constitution of USA, we will comply with Federal, State, Local, Tribal law
                        enforcement entities for all lawful requests pursuant to the Constitution of the United States
                        and Due Process of Law.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        12.2.
                    </div>
                    <div className='pl5'>
                        Independent Contractors: You agree that Your usage of the functionality of Our Platform
                        does not create an agency, franchise – franchisee, employer – employee, joint venture,
                        partnership or any such relationship. You and Guntraderz LLC are independent contractors.
                    </div>
                </div>
            </div>



        </div>
    </div>
</>

let PrivacyPolicy = <>
    <div className='termsPanel'>
        <div className='flx jcc font-weight-bold'>
            Privacy Policy
        </div>

    </div>
</>

let ReturnPolicy = <>
    <div className='termsPanel'>
        <div className='flx jcc font-weight-bold'>
            SHIPPING AND RETURNS POLICY
        </div>
        <div className=''>
            <div className=''>
                <div className=''>
                    1. SHIPPING
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        1.1.
                    </div>
                    <div className='pl5'>
                        Guntraderz.com (We, Platform, Site) is an advertising platform and is not a party to any
                        transactions on our Platform.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        1.2.
                    </div>
                    <div className='pl5'>
                        We do not stock, ship or handle any items sold on our Platform. Items are shipped by sellers
                        directly.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        1.3.
                    </div>
                    <div className='pl5'>
                        Once an order has been placed using any of the modes of purchase, it cannot be cancelled
                        unless agreed to explicitly by Seller. In such cases, a Platform Fee as per the Platform Fee
                        Schedule in effect at the time will still be charged. There are no exceptions and the Fee will not
                        be waived.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        1.4.
                    </div>
                    <div className='pl5'>
                        Buyers should give their Platform generated One Time Password (OTP) only upon receiving the
                        product. Once an OTP is provided funds will be transferred to the Seller and no refunds will be
                        given by the Platform. The only exception to this is if Seller allows returns
                    </div>
                </div>
            </div>
            <div className=''>
                <div className=''>
                    2. RETURNS
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        2.1.
                    </div>
                    <div className='pl5'>
                        Guntraderz.com is an advertising platform and is not a party to any transactions on our
                        Platform.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        2.2.
                    </div>
                    <div className='pl5'>
                        Each individual seller sets the terms for the items listed by them including for Returns.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        2.3.
                    </div>
                    <div className='pl5'>
                        Some of the common (but not all inclusive) terms are given below. These are provided for
                        general guidance. The terms set by each individual Seller for each item (which could be
                        different) will apply for that item.
                    </div>
                </div>
                <div className='flx ml25'>
                    <div className=''>
                        2.3.1.
                    </div>
                    <div className='pl5'>
                        No returns. All sales final.
                    </div>
                </div>
                <div className='flx ml25'>
                    <div className=''>
                        2.3.2.
                    </div>
                    <div className='pl5'>
                        Returns within a limited amount of time with or without re-stocking fee.
                    </div>
                </div>
                <div className='flx ml10'>
                    <div className=''>
                        2.4.
                    </div>
                    <div className='pl5'>
                        Each Buyer is responsible to read the terms set by Sellers of the item they purchase. By clicking
                        Buy, Trade or other modes of purchase provided by the Platform, Buyer agrees to the terms set
                        by the individual Seller on the Platform.
                    </div>
                </div>
            </div>
        </div>

    </div>
</>

let ShippingPolicy = <>
    <div className='termsPanel'>
        <div className='flx jcc font-weight-bold'>
            Shipping Policy
        </div>

    </div>
</>

let SecurityPolicy = <>
    <div className='termsPanel'>
        <div className='flx jcc font-weight-bold'>
            Security Policy
        </div>

    </div>
</>

const TermsAndPrivacy = ({ show, setShow, type }) => {

    return (<>
        <Modal {...{ show, setShow }} className="termCondition-modal">

            <div className="termCondition-header">
                {
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.TERMS && <span>Terms And Conditions</span> ||
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.POLICY && <span>Privacy Policy</span> ||
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.RETURN && <span>Return Policy</span> ||
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.SHIPPING && <span>Shipping Policy</span> ||
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.SECURITY && <span>Security Policy</span>
                }

            </div>
            <div className="termCondition-body">
                {
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.TERMS && TermAndConditions ||
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.POLICY && PrivacyPolicy ||
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.RETURN && ReturnPolicy ||
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.SHIPPING && ShippingPolicy ||
                    type === NOTIFICATION_CONSTANTS.TERMS_TYPE.SECURITY && SecurityPolicy
                }

            </div>
            <div className="termCondition-footer">

            </div>
        </Modal>
    </>)
}

export default TermsAndPrivacy