import React, { useState, useContext, useEffect } from 'react';
import { Formik } from "formik"
import * as Yup from 'yup';
import _ from 'lodash';
import Spinner from "rct-tpt-spnr";
import { Form, InputGroup } from 'react-bootstrap';
import ApiService from "../../../services/api.service";
import { SubmitField } from "../../Shared/InputType";
import { useAuthState } from '../../../contexts/AuthContext/context';
import { useHistory } from 'react-router-dom';
import GLOBAL_CONSTANTS from '../../../Constants/GlobalConstants';
import useToast from '../../../commons/ToastHook';

let defaultValues = {
    "appUserSid": '',
    "paymentVendor": 'Authorize',
    "paymentLoginId": '',
    "paymentTransactionId": ''
};
const PaymentKeySetup = ({ paymentKey, setPaymentKey, tabKey, getPaymentSetup=()=>{} }) => {

    const Toast = useToast();
    const spinner = useContext(Spinner);
    const history = useHistory();
    let userDetails = useAuthState();
    const [initialValues, setInitialValues] = useState(defaultValues);

    const schema = Yup.object().shape({
        paymentVendor: Yup.string()
            .required('Required!'),
        paymentLoginId: Yup.string()
            .required('Required!'),
        paymentTransactionId: Yup.string()
            .required("Required!"),
    });


    const onPaymentSetup = (values) => {
        try {
            spinner.show("Please wait...");
            let payload = {
                "appUserSid": userDetails?.user?.sid,
                "paymentVendor": values?.paymentVendor,
                "paymentLoginId": values?.paymentLoginId,
                "paymentTransactionId": values?.paymentTransactionId
            }

            ApiService.savePaymentSetup(payload).then(
                response => {
                    setPaymentKey(values);
                    getPaymentSetup();
                },
                err => {
                    console.error("Error occur when savePaymentSetup--", err);
                }
            ).finally(() => {
                spinner.hide();
            });

        } catch (err) {
            console.error("Exception occurred in onPaymentSetup --- ", err);
        }
    }


    const constructPayload = (values) => ({
        "appUserSid": userDetails?.user?.sid,
        "paymentVendor": values?.paymentVendor,
        "paymentLoginId": values?.paymentLoginId,
        "paymentTransactionId": values?.paymentTransactionId
    });


    useEffect(() => {
        if (!_.isEmpty(paymentKey)) {
            setInitialValues(paymentKey ? constructPayload(paymentKey) : defaultValues)
        }
    }, [paymentKey])

    useEffect(()=>{
        getPaymentSetup();
    },[tabKey])

    return <>
        <div className="myac-piBox">
            <Formik
                enableReinitialize={true}
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={onPaymentSetup}>
                {({ handleSubmit, isSubmitting, handleChange, resetForm, errors, values, isValid, dirty }) => (
                    <Form noValidate>
                        <Form.Group>
                            <Form.Label><span> Payment Provider<sup className='sup-color'>*</sup></span></Form.Label>
                            <Form.Control
                                as="select"
                                name="paymentVendor"
                                value={values.paymentVendor}
                                onChange={handleChange}
                                isInvalid={!!errors.paymentVendor}
                            >
                                <option  value=''>--Select Provider--</option>
                                {GLOBAL_CONSTANTS.PAYMENT.PROVIDERS_LIST.map((list, index) => {
                                    return <>

                                        <option key={list.value} value={list.sid}>{list.name}</option>

                                    </>
                                })}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.paymentVendor}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label><span>API Login Key<sup className='sup-color'>*</sup></span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter api login key"
                                name="paymentLoginId"
                                value={values.paymentLoginId}
                                onChange={handleChange}
                                isInvalid={!!errors.paymentLoginId}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.paymentLoginId}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label><span>Transaction Key<sup className='sup-color'>*</sup></span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter transaction key"
                                name="paymentTransactionId"
                                value={values.paymentTransactionId}
                                onChange={handleChange}
                                isInvalid={!!errors.paymentTransactionId}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.paymentTransactionId}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="row">
                            <div className="col-lg-12 text-right  mobile-off">
                                <ul className="profile-btnList">
                                    {/* <li onClick={() => { history.replace("/") }}><a class="submt-btn submt-btn-lignt mr10 pointer">Cancel</a></li> */}
                                    <li>
                                        <button
                                            type="submit"
                                            className="submt-btn submt-btn-dark"
                                            disabled={!isValid || isSubmitting || !dirty} 
                                            onClick={handleSubmit}
                                        >Save</button></li>
                                </ul>
                            </div>
                        </div>
                        <section class="mobile-btn-section desktop-off">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="proPg-btnArea">
                                            <ul>
                                                {/* <li onClick={() => { history.replace("/") }} ><a class="submt-btn submt-btn-lignt mr10 pointer">Cancel</a></li> */}
                                                <li>
                                                    <button
                                                        type="submit"
                                                        className="submt-btn submt-btn-dark probtn-pading"
                                                        disabled={!isValid || isSubmitting || !dirty} 
                                                        onClick={handleSubmit}
                                                    >Save</button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </Form>)}
            </Formik>
        </div>
    </>;
}

export default PaymentKeySetup;