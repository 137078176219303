import React from 'react';
import ICN_TD from './icn-trade-gn.png'
import './icon.css'

export const HomeIcon = ({width}) => <svg width={width}  fill="currentColor" viewBox="0 0 20 20"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>

export const MinusCircleIcon = ({width}) => <svg width={width} fill="currentColor" viewBox="0 0 20 20"><path d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clipRule="evenodd" fillRule="evenodd"></path></svg>

export const PlusCircleIcon = ({width}) => <svg width={width}  fill="currentColor" viewBox="0 0 20 20"><path d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" fillRule="evenodd"></path></svg>

export const CartIcon = ({width}) => <svg width={width}  fill="currentColor" viewBox="0 0 20 20"><path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path></svg>

export const TrashIcon = ({width}) => <svg width={width} fill="currentColor" viewBox="0 0 20 20"><path d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" fillRule="evenodd"></path></svg>

export const GitHubIcon = ({width}) => <svg width={width} viewBox="0 0 16 16" fill="currentColor"><path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg>

export const Logo = () => <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16"><defs></defs><g transform="translate(-35 -37)"><path className="b" d="M1,14a1,1,0,1,1,0-2H18a1,1,0,1,1,0,2ZM1,8A1,1,0,0,1,1,6H13a1,1,0,0,1,0,2ZM1,2A1,1,0,0,1,1,0H9A1,1,0,0,1,9,2Z" transform="translate(35 38)" /></g></svg>
export const ICN_STAR_Y = () => <svg xmlns="http://www.w3.org/2000/svg" width="6.305" height="6.042" viewBox="0 0 6.305 6.042"><path className="aaa" fill="#ffc107" d="M6.288,2.775A.334.334,0,0,0,6,2.545L4.18,2.38,3.461.7a.335.335,0,0,0-.616,0L2.125,2.38.3,2.545a.335.335,0,0,0-.19.586L1.49,4.338,1.084,6.124a.335.335,0,0,0,.5.362l1.57-.938,1.569.938a.335.335,0,0,0,.5-.362L4.815,4.338,6.19,3.132A.335.335,0,0,0,6.288,2.775Zm0,0" transform="translate(0 -0.492)" /></svg>;
export const ICN_PROFILE = () => <svg xmlns="http://www.w3.org/2000/svg" width="14.549" height="13.856" viewBox="0 0 14.549 13.856"><g transform="translate(0 -13.415)"><path className="aa" fill="#707070" d="M133.881,21.191a3.888,3.888,0,1,0-3.894-3.894A3.895,3.895,0,0,0,133.881,21.191Zm0-6.761a2.867,2.867,0,1,1-2.867,2.867A2.875,2.875,0,0,1,133.881,14.43Z" transform="translate(-126.63 0)" /><path className="aa" d="M.514,346.325H14.036a.51.51,0,0,0,.514-.514,4.892,4.892,0,0,0-4.886-4.886H4.886A4.891,4.891,0,0,0,0,345.812.51.51,0,0,0,.514,346.325Zm4.372-4.372H9.664a3.856,3.856,0,0,1,3.822,3.345H1.063A3.866,3.866,0,0,1,4.886,341.953Z" transform="translate(0 -319.054)" /></g></svg>;
export const ICN_PAY_CARD = () =>  <svg xmlns="http://www.w3.org/2000/svg" width="15.18" height="11.25" viewBox="0 0 15.18 11.25"><g transform="translate(0 -55.2)"><g transform="translate(9.733 62.426)"><g transform="translate(0 0)"><path className="aa" fill="#707070" d="M276.846,258.4a1.283,1.283,0,0,0-1.442,0,1.269,1.269,0,0,0-.719-.221,1.287,1.287,0,0,0,0,2.574,1.269,1.269,0,0,0,.719-.221,1.287,1.287,0,0,0,1.442-2.133Zm-.288,1.438a.57.57,0,0,1-.8.071.5.5,0,0,1-.075-.075.344.344,0,0,0-.274-.125.353.353,0,0,0-.274.128.557.557,0,0,1-.441.206.576.576,0,0,1-.577-.573.572.572,0,0,1,1.015-.367.357.357,0,0,0,.5.053.377.377,0,0,0,.053-.053.571.571,0,0,1,.876.733Z" transform="translate(-273.4 -258.175)" /></g></g><g transform="translate(0 55.2)"><g transform="translate(0 0)"><path className="aa" d="M15.166,59.629c0-.032.014-.068.014-.1s-.014-.068-.014-.1v-.762a1.515,1.515,0,0,0-1.524-1.506h-.434v-.431a1.515,1.515,0,0,0-1.5-1.527H1.509A1.521,1.521,0,0,0,0,56.727v6.259a1.5,1.5,0,0,0,1.5,1.506h.424v.431a1.53,1.53,0,0,0,1.52,1.527h10.2a1.535,1.535,0,0,0,1.524-1.527V61.3c0-.032.014-.068.014-.1s-.014-.068-.014-.1ZM1.922,58.664V63.78H1.509a.79.79,0,0,1-.8-.78V56.727a.809.809,0,0,1,.8-.815h10.2a.8.8,0,0,1,.79.815v.431H3.442A1.512,1.512,0,0,0,1.922,58.664Zm12.532,6.259a.819.819,0,0,1-.812.815H3.442a.814.814,0,0,1-.808-.815V61.537h11.82Zm0-4.1H2.634V59.9h11.82Zm0-1.638H2.634v-.523a.8.8,0,0,1,.808-.794h10.2a.8.8,0,0,1,.812.794v.523Z" transform="translate(0 -55.2)" /></g></g></g></svg>;
export const ICN_LOCK = () => <svg xmlns="http://www.w3.org/2000/svg" width="12.521" height="17.366" viewBox="0 0 12.521 17.366"><g transform="translate(-65.8)"><g transform="translate(65.8)"><path className="aa" fill="#707070" d="M76.661,7.385V4.635a4.611,4.611,0,0,0-1.377-3.313A4.465,4.465,0,0,0,72.114,0h-.059a4.624,4.624,0,0,0-4.6,4.635v2.75A1.878,1.878,0,0,0,65.8,9.274v6.178a1.9,1.9,0,0,0,1.889,1.914h8.744a1.9,1.9,0,0,0,1.889-1.914V9.274A1.887,1.887,0,0,0,76.661,7.385Zm-8.471-2.75h0A3.9,3.9,0,0,1,72.059.725h0a3.813,3.813,0,0,1,2.7,1.112,3.9,3.9,0,0,1,1.156,2.8V7.389h-.81V4.635a3.1,3.1,0,0,0-.917-2.227,2.985,2.985,0,0,0-2.11-.876h-.022A3.083,3.083,0,0,0,69,4.631V7.389h-.81Zm6.189,0V7.389H69.739V4.635a2.344,2.344,0,0,1,2.327-2.364h.022a2.258,2.258,0,0,1,1.594.663A2.373,2.373,0,0,1,74.378,4.635Zm3.24,10.828h0a1.167,1.167,0,0,1-1.167,1.167H67.7a1.167,1.167,0,0,1-1.167-1.167V9.292A1.167,1.167,0,0,1,67.7,8.125h8.748a1.167,1.167,0,0,1,1.167,1.167Z" transform="translate(-65.8)" /></g><g transform="translate(70.73 11.188)"><g transform="translate(0)"><path className="aa" d="M202.3,304.861a1.327,1.327,0,1,0-1.642,1.642v1.031a.368.368,0,0,0,.736,0V306.5A1.324,1.324,0,0,0,202.3,304.861Zm-1.274.957a.593.593,0,1,1,.593-.593A.592.592,0,0,1,201.029,305.818Z" transform="translate(-199.7 -303.9)" /></g></g></g></svg>;
export const ICN_BOOKMARK_GREEN = () => <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="10.6" viewBox="0 0 7.999 10.6"><path className="bd" fill="#5ba018" d="M33.113,0H26.962a.939.939,0,0,0-.941.9V10a.7.7,0,0,0,.118.4.46.46,0,0,0,.381.2.686.686,0,0,0,.436-.184l2.75-2.337a.545.545,0,0,1,.668,0l2.743,2.336a.662.662,0,0,0,.426.184c.241,0,.476-.186.476-.6V.9A.91.91,0,0,0,33.113,0Z" transform="translate(-26.022)" /></svg>;
export const ICN_BOOKMARK_WHITE = () => <svg xmlns="http://www.w3.org/2000/svg" width="7.999" height="10.6" viewBox="0 0 10.392 13.446"><path className="a" fill="#fff" stroke="#487916" d="M34.349,0H27.126a1.1,1.1,0,0,0-1.1,1.057V11.739a.819.819,0,0,0,.139.475.54.54,0,0,0,.447.232.805.805,0,0,0,.512-.216l3.23-2.744a.64.64,0,0,1,.785,0l3.221,2.743a.777.777,0,0,0,.5.216c.283,0,.559-.219.559-.707V1.057A1.068,1.068,0,0,0,34.349,0Z" transform="translate(-25.522 0.5)" /></svg>;
export const ICN_CLOSE_RED = () => <svg xmlns="http://www.w3.org/2000/svg" width="8.898" height="9.011" viewBox="0 0 8.898 9.011"><g transform="translate(0)"><g transform="translate(8.898 0.07) rotate(90)"><g transform="translate(0 0)"><path fill="#ea612e" className="a" d="M8.739.18a.626.626,0,0,0-.878,0L.42,7.536c-1.063,1.051.2,1.542.439,1.3l7.88-7.791A.609.609,0,0,0,8.739.18Z" transform="translate(0)"/></g></g><g transform="translate(8.869 9.011) rotate(180)"><g transform="translate(0)"><path className="a"  fill="#ea612e" d="M8.684.182a.614.614,0,0,0-.873,0L.418,7.631C-.639,8.7.613,9.193.854,8.95l7.83-7.889A.625.625,0,0,0,8.684.182Z"/></g></g></g></svg>;
export const ICN_YES_GREEN = () => <svg xmlns="http://www.w3.org/2000/svg" width="10.676" height="8" viewBox="0 0 10.676 8"><g transform="translate(10.676 7.901) rotate(180)"><g transform="translate(0 0)"><path className="a" fill="#5ba018" d="M8.112.16A.6.6,0,0,0,7.3.16L.39,6.692C-.6,7.625.573,8.061.8,7.848L8.112.931A.525.525,0,0,0,8.112.16Z"/></g></g><path fill="#5ba018" className="a" d="M.572,0H4A.572.572,0,1,1,4,1.143H.572A.572.572,0,0,1,.572,0Z" transform="translate(0.913 3.66) rotate(53)"/></svg>;
export const ICN_TRADE_MX = () => <svg xmlns="http://www.w3.org/2000/svg" width="15.38" height="15.346" viewBox="0 0 15.38 15.346"><g transform="translate(0 8.651)"><path fill="#477716" className="a" d="M4.085,289.232,0,288.557v6.02l4.085.675,4.085-.675v-6.02Zm-.451,5.032L.9,293.812v-4.193l2.734.452Zm3.635-.452-2.734.452v-4.193l2.734-.452Z" transform="translate(0 -288.557)"/></g><g transform="translate(7.209 0)"><path fill="#477716" className="a" d="M244.085,1.232,240,.557v6.02l4.085.675,4.085-.675V.557Zm-.451,5.032L240.9,5.813V1.619l2.734.452Zm3.635-.452-2.733.452V2.071l2.733-.452Z" transform="translate(-240 -0.557)"/></g><g transform="translate(0 1.506)"><g transform="translate(0 0)"><path fill="#477716" className="a" d="M6.441,52.048,5.073,50.679V51.6A5.253,5.253,0,0,0,0,56.854H.9A4.361,4.361,0,0,1,5.073,52.5v.914Z" transform="translate(0 -50.679)"/></g></g><g transform="translate(8.938 7.68)"><path fill="#477716" className="a" d="M303.106,256.24a4.361,4.361,0,0,1-4.172,4.351v-.914l-1.368,1.368,1.368,1.369v-.921a5.253,5.253,0,0,0,5.073-5.253Z" transform="translate(-297.566 -256.24)"/></g></svg>;
export const ICN_TRADE_EX = <img className="small-img" src={ICN_TD}/>
export const IcnCircleInfo = ({width="14", height="14", fill="#2e35ff"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14">
<g id="Group_606" data-name="Group 606" transform="translate(-48 -420)">
  <g id="Rectangle_1508" data-name="Rectangle 1508" transform="translate(48 420)" fill="none" stroke="#2b40f5" strokeWidth="1">
    <rect width="14" height="14" rx="7" stroke="none"/>
    <rect x="0.5" y="0.5" width="13" height="13" rx="6.5" fill="none"/>
  </g>
  <text id="_" data-name="!" transform="translate(54 430)" fill={fill} fontSize="8" fontFamily="GillSans, Gill Sans"><tspan x="0" y="0">!</tspan></text>
</g>
</svg>
export const IcnCalender = ({width="10.5", height="12", fill="#111"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10.5 12"><path className="a" fill={fill} d="M0,10.875A1.125,1.125,0,0,0,1.125,12h8.25A1.125,1.125,0,0,0,10.5,10.875V4.5H0ZM7.5,6.281A.282.282,0,0,1,7.781,6h.938A.282.282,0,0,1,9,6.281v.938a.282.282,0,0,1-.281.281H7.781A.282.282,0,0,1,7.5,7.219Zm0,3A.282.282,0,0,1,7.781,9h.938A.282.282,0,0,1,9,9.281v.938a.282.282,0,0,1-.281.281H7.781a.282.282,0,0,1-.281-.281Zm-3-3A.282.282,0,0,1,4.781,6h.938A.282.282,0,0,1,6,6.281v.938a.282.282,0,0,1-.281.281H4.781A.282.282,0,0,1,4.5,7.219Zm0,3A.282.282,0,0,1,4.781,9h.938A.282.282,0,0,1,6,9.281v.938a.282.282,0,0,1-.281.281H4.781a.282.282,0,0,1-.281-.281Zm-3-3A.282.282,0,0,1,1.781,6h.938A.282.282,0,0,1,3,6.281v.938a.282.282,0,0,1-.281.281H1.781A.282.282,0,0,1,1.5,7.219Zm0,3A.282.282,0,0,1,1.781,9h.938A.282.282,0,0,1,3,9.281v.938a.282.282,0,0,1-.281.281H1.781a.282.282,0,0,1-.281-.281ZM9.375,1.5H8.25V.375A.376.376,0,0,0,7.875,0h-.75A.376.376,0,0,0,6.75.375V1.5h-3V.375A.376.376,0,0,0,3.375,0h-.75A.376.376,0,0,0,2.25.375V1.5H1.125A1.125,1.125,0,0,0,0,2.625V3.75H10.5V2.625A1.125,1.125,0,0,0,9.375,1.5Z"/></svg>;
export const IcnCalender1 = ({width="15.925", height="15.925", fill="#707070"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}viewBox="0 0 15.925 15.925"><path className="a" fill={fill} d="M13.873,1.244H12.442V.5a.5.5,0,0,0-1,0v.747H4.479V.5a.5.5,0,0,0-1,0v.747H2.053A2.055,2.055,0,0,0,0,3.3V13.873a2.055,2.055,0,0,0,2.053,2.053h11.82a2.055,2.055,0,0,0,2.053-2.053V3.3A2.055,2.055,0,0,0,13.873,1.244Zm-11.82,1H3.484v.5a.5.5,0,1,0,1,0v-.5h6.967v.5a.5.5,0,1,0,1,0v-.5h1.431A1.059,1.059,0,0,1,14.93,3.3V4.479H1V3.3A1.059,1.059,0,0,1,2.053,2.24ZM13.873,14.93H2.053A1.059,1.059,0,0,1,1,13.873v-8.4H14.93v8.4A1.059,1.059,0,0,1,13.873,14.93Z"/></svg>;
export const IcnLocation = ({width="9.835", height="14.05", fill="#44c4a1"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 9.835 14.05"><path fill={fill} className="a" d="M12.417,3A4.914,4.914,0,0,0,7.5,7.918c0,3.688,4.917,9.132,4.917,9.132s4.918-5.444,4.918-9.132A4.914,4.914,0,0,0,12.417,3Zm0,6.674a1.756,1.756,0,1,1,1.756-1.756A1.757,1.757,0,0,1,12.417,9.674Z" transform="translate(-7.5 -3)"/></svg>;
export const IcnWarningCircle = ({width="10", height="10"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 10"><g transform="translate(-46 -716)"><g className="a" stroke="#ea612e" fill="none" transform="translate(46 716)"><rect className="c" stroke="none" width="10" height="10" rx="5"/><rect className="d" fill="none" x="0.5" y="0.5" width="9" height="9" rx="4.5"/></g><path className="b" fill="#ea612e" d="M1.175-.3a.337.337,0,0,1-.1.249.347.347,0,0,1-.253.1.371.371,0,0,1-.265-.1A.331.331,0,0,1,.445-.3.363.363,0,0,1,.551-.57.348.348,0,0,1,.809-.677a.35.35,0,0,1,.262.107A.365.365,0,0,1,1.175-.3ZM.75-1.277.7-1.427a5.125,5.125,0,0,1-.142-.8A8.491,8.491,0,0,1,.48-3.287q0-.858.337-.858t.328.835A7.95,7.95,0,0,1,1.014-1.89l-.079.448-.047.17q-.026.091-.079.091Q.776-1.181.75-1.277Z" transform="translate(50 723)"/></g></svg>;
export const IcnClock = ({width="15.926", height="15.926", fill="#707070"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}  viewBox="0 0 15.926 15.926"><path className="a" fill={fill} d="M14.25,4.19a.664.664,0,0,0-.308.886A6.568,6.568,0,0,1,14.6,7.963,6.636,6.636,0,1,1,7.963,1.327a6.556,6.556,0,0,1,4.119,1.432.663.663,0,1,0,.826-1.039,7.964,7.964,0,1,0,3.018,6.243,7.882,7.882,0,0,0-.79-3.464A.663.663,0,0,0,14.25,4.19Zm0,0" transform="translate(0 0)"/><path className="a" d="M176.664,64a.664.664,0,0,0-.664.664v4.645a.664.664,0,0,0,.664.664h3.318a.664.664,0,1,0,0-1.327h-2.654V64.664A.664.664,0,0,0,176.664,64Zm0,0" transform="translate(-168.701 -61.346)"/></svg>;
export const IcnTrashRed = ({width="13.333", height="16", fill="#e27474"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13.333 16"><path className="a" fill={fill} d="M8.083,18.726A1.781,1.781,0,0,0,9.861,20.5h7.111a1.781,1.781,0,0,0,1.778-1.774V8.5H8.083Zm12-12.893H16.75L15.634,4.5H11.2L10.083,5.833H6.75V7.167H20.083Z" transform="translate(-6.75 -4.5)"/></svg>
export const ICN_CHEVRON_RIGHT_G = <svg xmlns="http://www.w3.org/2000/svg" width="5.639" height="9.292" viewBox="0 0 5.639 9.292"><path fill="#909090" className="a" d="M4.683,14.173.547,10.037a.511.511,0,0,1,0-.722l.482-.482a.511.511,0,0,1,.721,0l3.293,3.277L8.336,8.832a.511.511,0,0,1,.721,0l.482.482a.511.511,0,0,1,0,.722L5.4,14.173A.511.511,0,0,1,4.683,14.173Z" transform="translate(-8.683 9.69) rotate(-90)"/></svg>
export const ICN_SEARCH = ({height="18.727", width="18.66", fill="#fff"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18.66 18.727"><path className="a" fill={fill} d="M25.385,34.392a7.231,7.231,0,1,1,5.432-12.015,7.184,7.184,0,0,1,.549,8.891l4.976,4.94a1.439,1.439,0,0,1,0,2.044,1.462,1.462,0,0,1-2.058,0h0l-5-4.964v-.015a7.257,7.257,0,0,1-3.9,1.119Zm0-13.515a6.3,6.3,0,1,0,6.342,6.3,6.324,6.324,0,0,0-6.342-6.3Z" transform="translate(-18.108 -19.949)"/></svg>;
export const ICN_SEARCH_NOT_FOUND = () => <svg xmlns="http://www.w3.org/2000/svg" width="47.675" height="47.687" viewBox="0 0 47.675 47.687"><g transform="translate(-183 -138)"><path className="a" fill="#909090" d="M51.616,48.729,38.357,35.345a18.9,18.9,0,1,0-2.868,2.905l13.173,13.3a2.041,2.041,0,0,0,2.88.074A2.054,2.054,0,0,0,51.616,48.729ZM23.508,38.412a14.921,14.921,0,1,1,10.553-4.37A14.829,14.829,0,0,1,23.508,38.412Z" transform="translate(178.5 133.507)"/><path className="a" d="M21.523,8.923l-1.4-1.4-5.6,5.6-5.6-5.6-1.4,1.4,5.6,5.6-5.6,5.6,1.4,1.4,5.6-5.6,5.6,5.6,1.4-1.4-5.6-5.6Z" transform="translate(188.015 142.843)"/></g></svg>;
export const IcnAdd = ({width="14", height="14", fill="#fff", viewport="0 0 14 14"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewport}><g transform="translate(-179 -654)"><circle cx="7" cy="7" r="7" transform="translate(179 654)"/><path className="a" fill={fill} fillRule={"evenodd"} d="M4.444,3.556V0H3.556V3.556H0v.889H3.556V8h.889V4.444H8V3.556Z" transform="translate(182.273 657.273)"/></g></svg>
export const IcnDownSmall = ({width="8", height="5", fill="#000000"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}  viewBox="0 0 9.887 6"><path fill={fill} className="a" d="M4.957,14.524l-4.4-4.4a.543.543,0,0,1,0-.768l.513-.513a.543.543,0,0,1,.768,0l3.5,3.487,3.5-3.487a.543.543,0,0,1,.768,0l.513.513a.543.543,0,0,1,0,.768l-4.4,4.4A.543.543,0,0,1,4.957,14.524Z" transform="translate(-0.398 -8.683)" /></svg>
export const ICN_FILTER = <svg xmlns="http://www.w3.org/2000/svg" width="18.005" height="17.255" viewBox="0 0 18.005 17.255"><g transform="translate(0 -10.667)"><g transform="translate(0 22.671)"><path d="M17.255,353.876h-2a2.609,2.609,0,0,0-5.007,0H.75a.75.75,0,0,0,0,1.5h9.5a2.609,2.609,0,0,0,5.007,0h2a.75.75,0,0,0,0-1.5Zm-4.5,1.876a1.125,1.125,0,1,1,1.125-1.125A1.127,1.127,0,0,1,12.754,355.751Z" transform="translate(0 -352)"/></g><g transform="translate(0 10.667)"><g transform="translate(0 0)"><path d="M17.255,12.543h-2a2.609,2.609,0,0,0-5.007,0H.75a.75.75,0,1,0,0,1.5h9.5a2.609,2.609,0,0,0,5.007,0h2a.75.75,0,0,0,0-1.5Zm-4.5,1.876a1.125,1.125,0,1,1,1.125-1.125A1.127,1.127,0,0,1,12.754,14.418Z" transform="translate(0 -10.667)"/></g></g><g transform="translate(0 16.669)"><path d="M17.255,183.21h-9.5a2.609,2.609,0,0,0-5.007,0h-2a.75.75,0,1,0,0,1.5h2a2.609,2.609,0,0,0,5.007,0h9.5a.75.75,0,0,0,0-1.5Zm-12,1.876a1.125,1.125,0,1,1,1.125-1.125A1.127,1.127,0,0,1,5.252,185.085Z" transform="translate(0 -181.334)"/></g></g></svg>
export const ICN_GET_SERVICE = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12.801" viewBox="0 0 16 12.801"><path fill="#07619a" className="a" d="M8,9.6H3.2v-4H1.6V12a.8.8,0,0,0,.8.8H8.8a.8.8,0,0,0,.8-.8V5.6H8Zm7.866-6.046L13.736.355A.8.8,0,0,0,13.069,0H2.938a.8.8,0,0,0-.665.355L.14,3.555A.8.8,0,0,0,.8,4.8H15.2A.8.8,0,0,0,15.869,3.555ZM12.8,12.4a.4.4,0,0,0,.4.4H14a.4.4,0,0,0,.4-.4V5.6H12.8Z" transform="translate(-0.004)"/></svg>
export const ICN_PRIMARY = <svg xmlns="http://www.w3.org/2000/svg" width="9.273" height="10.164" viewBox="0 0 9.273 10.164"><g transform="translate(-4.01 -2.539)"><path className="icn-primary" d="M15.4,8.706,11.25,6.315" transform="translate(-4.677 -2.295)"/><path className="icn-primary" d="M12.792,9.452V5.766a.921.921,0,0,0-.461-.8L9.107,3.127a.921.921,0,0,0-.921,0L4.961,4.969a.921.921,0,0,0-.461.8V9.452a.921.921,0,0,0,.461.8l3.225,1.843a.921.921,0,0,0,.921,0l3.225-1.843a.921.921,0,0,0,.461-.8Z"/><path className="icn-primary" d="M4.905,10.44l4.022,2.326,4.022-2.326" transform="translate(-0.281 -5.153)"/><path className="icn-primary" d="M18,22.644V18" transform="translate(-9.354 -10.391)"/></g></svg>
export const ICN_PRIMARY_LG = <svg xmlns="http://www.w3.org/2000/svg" width="13.954" height="15.325" viewBox="0 0 13.954 15.325"><g transform="translate(-3.8 -2.332)"><path className="icn-primary-lg" d="M17.527,9.935,11.25,6.315" transform="translate(-3.611 -1.772)"/><path className="icn-primary-lg" d="M17.054,12.766V7.186a1.4,1.4,0,0,0-.7-1.207L11.474,3.19a1.4,1.4,0,0,0-1.395,0L5.2,5.98a1.4,1.4,0,0,0-.7,1.207v5.58a1.4,1.4,0,0,0,.7,1.207l4.882,2.79a1.4,1.4,0,0,0,1.395,0l4.882-2.79a1.4,1.4,0,0,0,.7-1.207Z" transform="translate(0)"/><path className="icn-primary-lg" d="M4.905,10.44l6.089,3.522,6.089-3.522" transform="translate(-0.217 -3.979)"/><path className="icn-primary-lg" d="M18,25.03V18" transform="translate(-7.223 -8.024)"/></g></svg>
export const ICN_MORE = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18"><path d="M9 5.5c.83 0 1.5-.67 1.5-1.5S9.83 2.5 9 2.5 7.5 3.17 7.5 4 8.17 5.5 9 5.5zm0 2c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5S9.83 7.5 9 7.5zm0 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"/></svg>
export const ICN_LIST = ({width="12.556", height="16.741", fill="#5ba018"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12.556 16.741"><path d="M10.986,2.093H8.37a2.093,2.093,0,0,0-4.185,0H1.569A1.57,1.57,0,0,0,0,3.662V15.172a1.57,1.57,0,0,0,1.569,1.569h9.417a1.57,1.57,0,0,0,1.569-1.569V3.662A1.57,1.57,0,0,0,10.986,2.093ZM3.139,13.864a.785.785,0,1,1,.785-.785A.783.783,0,0,1,3.139,13.864Zm0-3.139a.785.785,0,1,1,.785-.785A.783.783,0,0,1,3.139,10.725Zm0-3.139A.785.785,0,1,1,3.924,6.8.783.783,0,0,1,3.139,7.586ZM6.278,1.308a.785.785,0,1,1-.785.785A.783.783,0,0,1,6.278,1.308ZM10.463,13.34a.262.262,0,0,1-.262.262H5.493a.262.262,0,0,1-.262-.262v-.523a.262.262,0,0,1,.262-.262H10.2a.262.262,0,0,1,.262.262Zm0-3.139a.262.262,0,0,1-.262.262H5.493a.262.262,0,0,1-.262-.262V9.678a.262.262,0,0,1,.262-.262H10.2a.262.262,0,0,1,.262.262Zm0-3.139a.262.262,0,0,1-.262.262H5.493a.262.262,0,0,1-.262-.262V6.539a.262.262,0,0,1,.262-.262H10.2a.262.262,0,0,1,.262.262Z" fill={fill}/></svg>
export const ICN_GEO_LOCATION = ({width="16", height="16", fill="#3e83f2"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16"><path d="M9.5,6.591A2.909,2.909,0,1,0,12.409,9.5,2.908,2.908,0,0,0,9.5,6.591ZM16,8.773A6.541,6.541,0,0,0,10.227,3V1.5H8.773V3A6.541,6.541,0,0,0,3,8.773H1.5v1.455H3A6.541,6.541,0,0,0,8.773,16v1.5h1.455V16A6.541,6.541,0,0,0,16,10.227h1.5V8.773ZM9.5,14.591A5.091,5.091,0,1,1,14.591,9.5,5.087,5.087,0,0,1,9.5,14.591Z" transform="translate(-1.5 -1.5)" fill={fill}/></svg>
export const ICN_CHEVRON_RIGHT = ({width="4.586", height="7.971", fill="none", stroke="#555"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 4.586 7.971"><path id="Icon_feather-chevron-right" data-name="Icon feather-chevron-right" d="M13.5,15.274l3.137-3.137L13.5,9" transform="translate(-12.651 -8.151)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" opacity="1"/></svg>
export const ICN_CHEVRON_DOWN = ({width="7.971", height="4.586", fill="none", stroke="#555"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 7.971 4.586"><path id="Icon_feather-chevron-right" data-name="Icon feather-chevron-right" d="M13.5,15.274l3.137-3.137L13.5,9" transform="translate(16.123 -12.651) rotate(90)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2"/></svg>
export const TRADE_ICON = ({width="21.962", height="21.112", stroke="#477716"}) => <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21.962 21.112">
<g id="trade" transform="translate(0.499 0.259)">
  <g id="Group_2797" data-name="Group 2797" transform="translate(0 0)">
    <g id="Group_31" data-name="Group 31" transform="translate(0.588 0)">
      <g id="Group_30" data-name="Group 30">
        <path id="Path_645" data-name="Path 645" d="M9.454,52.688,7.446,50.679v1.353A7.71,7.71,0,0,0,0,59.742H1.323a6.4,6.4,0,0,1,6.124-6.387V54.7Z" transform="translate(0 -50.679)" fill="#477716"/>
      </g>
    </g>
    <g id="Group_33" data-name="Group 33" transform="translate(10.043 11.791)">
      <g id="Group_32" data-name="Group 32">
        <path id="Path_646" data-name="Path 646" d="M305.7,256.24a6.4,6.4,0,0,1-6.124,6.387v-1.341l-2.008,2.008,2.008,2.009V263.95a7.711,7.711,0,0,0,7.446-7.71Z" transform="translate(-297.566 -256.24)" fill="#477716"/>
      </g>
    </g>
    <g id="Icon_feather-package" data-name="Icon feather-package" transform="translate(13.21 0.205)">
      <path id="Path_1306" data-name="Path 1306" d="M15.128,8.551,11.25,6.315" transform="translate(-9.311 -5.364)" fill="none" stroke={stroke} trokelinecap="round" strokeLinejoin="round" strokeWidth="0.9"/>
      <path id="Path_1307" data-name="Path 1307" d="M12.255,9.034V5.587a.862.862,0,0,0-.431-.745L8.808,3.119a.862.862,0,0,0-.862,0L4.931,4.842a.862.862,0,0,0-.431.745V9.034a.862.862,0,0,0,.431.745L7.947,11.5a.862.862,0,0,0,.862,0l3.016-1.723a.862.862,0,0,0,.431-.745Z" transform="translate(-4.5 -3.003)" fill="none" stroke={stroke} trokelinecap="round" strokeLinejoin="round" strokeWidth="0.9"/>
      <path id="Path_1308" data-name="Path 1308" d="M4.905,10.44l3.761,2.176,3.761-2.176" transform="translate(-4.789 -8.304)" fill="none" stroke={stroke} trokelinecap="round" strokeLinejoin="round" strokeWidth="0.9"/>
      <path id="Path_1309" data-name="Path 1309" d="M18,22.343V18" transform="translate(-14.122 -13.693)" fill="none" stroke={stroke} trokelinecap="round" strokeLinejoin="round" strokeWidth="0.9"/>
    </g>
    <g id="Icon_feather-package-2" data-name="Icon feather-package" transform="translate(0 10.479)">
      <path id="Path_1306-2" data-name="Path 1306" d="M15.128,8.551,11.25,6.315" transform="translate(-9.311 -5.364)" fill="none" stroke={stroke} trokelinecap="round" strokeLinejoin="round" strokeWidth="0.9"/>
      <path id="Path_1307-2" data-name="Path 1307" d="M12.255,9.034V5.587a.862.862,0,0,0-.431-.745L8.808,3.119a.862.862,0,0,0-.862,0L4.931,4.842a.862.862,0,0,0-.431.745V9.034a.862.862,0,0,0,.431.745L7.947,11.5a.862.862,0,0,0,.862,0l3.016-1.723a.862.862,0,0,0,.431-.745Z" transform="translate(-4.5 -3.003)" fill="none" stroke={stroke} trokelinecap="round" strokeLinejoin="round" strokeWidth="0.9"/>
      <path id="Path_1308-2" data-name="Path 1308" d="M4.905,10.44l3.761,2.176,3.761-2.176" transform="translate(-4.789 -8.304)" fill="none" stroke={stroke} trokelinecap="round" strokeLinejoin="round" strokeWidth="0.9"/>
      <path id="Path_1309-2" data-name="Path 1309" d="M18,22.343V18" transform="translate(-14.122 -13.693)" fill="none" stroke={stroke} trokelinecap="round" strokeLinejoin="round" strokeWidth="0.9"/>
    </g>
  </g>
</g>
</svg>
