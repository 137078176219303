import React, { Fragment, useEffect, useState } from 'react'
import Slider from '../../Shared/SliderModal/SliderModal';
import _ from 'lodash';
import bgCheckMarkIcon from '../../../assets/images/icon/bg_checkmark.png';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { RiErrorWarningFill } from "react-icons/ri";


import './PaymentSetup.css';

const TransactionSlider = ({ show, setShow, txnInfo, setTxnDetails }) => {

    const [txnObj, setTxnObj] = useState(txnInfo);


    // this method trigger to get image url
    const getMyImage = (item) => {
        let imageUrl = '../images/no-image-available.png';
        if (!_.isEmpty(item.listingDetailsContent)) {
            const imagesByItem = JSON.parse(item.listingDetailsContent)[0];
            imageUrl = !_.isEmpty(imagesByItem) ? _.isObject(imagesByItem.fileName) ? imagesByItem.fileName : imagesByItem.fileName : '../images/no-image-available.png';
        }
        return imageUrl;
    }

    useEffect(() => {
        setTxnObj(txnInfo);
    }, [txnInfo])

    return (<Fragment>
        <Slider closeModal={() => { setShow(false) }} id="orderModal" animation="slide" speed="faster" closeIcon={() => { setShow(false) }}
            toggle={show} size="semi-medium" direction="right">
            <div className="slider-container notification-modal">
                <div className="txn-header px10">
                    <div className='txn-status-icon'>
                        {
                            txnObj?.transactionStatus === "SUCCESS" && <img src={bgCheckMarkIcon} alt="ordered" />
                        }
                    </div>
                    <div className='txn-status'>
                        <div className='txn-status-msg'>
                            {
                                txnObj?.transactionStatus === "SUCCESS" ? "Transaction Successful" : "Transaction Pending"
                            }
                        </div>
                        <div className='txn-date'>
                            {
                                txnObj?.dateTime
                            }
                        </div>
                    </div>
                </div>
                <div className='txn-body p10'>
                    {/* <div className='txn-settled py10'>
                        <div className=''>
                            {
                                txnObj?.settlementStatus === "SETTLED" ? <IoMdCheckmarkCircle /> : <RiErrorWarningFill />
                            }
                        </div>
                        <div>
                            {txnObj?.settlementStatus === "SETTLED" ? "Settled Successful" : "Settled Pending"
                            }
                        </div>
                        <div>
                            {txnObj?.settledOn}
                        </div>
                        <div className=''>
                            Settled Amount : $ <span className='fwb'>{txnObj?.settlementAmount}</span>
                        </div>
                        <div className=''>
                            Settled To : <span className='fwb'>{txnObj?.settledToId?.email}</span>
                        </div>
                    </div> */}
                    <div className='txn-paid-to px10'>
                        <div className='py5 f16 fwb'>
                            Paid to
                        </div>
                        <div className='py5 txn-card'>
                            <div className='card-with-icon'>
                                <div className='txn-card-icon'>
                                    <span>VI</span>
                                </div>
                                <div className='txn-card-number f18'>
                                    <div>
                                        {txnObj?.merchantName}
                                    </div>
                                    <div className='f16'>
                                        {txnObj?.creditorId?.email}
                                    </div>
                                </div>
                            </div>
                            <div className='amount'>
                                <div className='f18 fwb'>
                                    $ {txnObj?.totalAmount ? txnObj.totalAmount : 0}
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className='txn-debited-from py5 px10'>
                        <div className='py5 f16 fwb'>
                            Debited from
                        </div>
                        <div className='py5 txn-card'>
                            <div className='card-with-icon'>
                                <div className='txn-card-icon'>
                                    <span>MA</span>
                                </div>
                                <div className='txn-card-number f18'>
                                    <div>
                                        {txnObj?.accountNumber}
                                    </div>
                                    <div>
                                        {txnObj?.accountType}
                                    </div>
                                </div>
                            </div>
                            <div className='amount'>
                                <div className='f18 fwb'>
                                    $ {txnObj?.totalAmount ? txnObj.totalAmount : 0}
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className='txn-details f16 py5 px10'>
                        <div className='py5 f16 fwb'>
                            Transfer Details
                        </div>
                        <div className='f16'>
                            Transaction ID : {txnObj?.transactionId}
                        </div>
                        <div className='f16'>
                            Status : {txnObj?.transactionStatus === "SUCCESS" ? "Success" : "Failed"}
                        </div>
                    </div>
                    <div className='txn-debited-from py5 px10'>
                        <div className='py5 f16 fwb'>
                            Order Summary
                        </div>
                        <div className='py5 txn-card'>
                            <div className='card-with-icon'>
                                <div className='order-img' style={{ backgroundImage: `url(${getMyImage(txnObj?.listingDetailsView)})` }}>
                                    {/* <span>MA</span> */}
                                </div>
                                <div className='txn-card-number'>
                                    <div className='f14'>
                                        {txnObj?.listingDetailsView?.title}
                                    </div>
                                    <div className='f14'>
                                        Qty: {txnObj?.orderDetails?.quantity}
                                    </div>
                                </div>
                            </div>
                            {/* <div className='amount'>
                                <div className='f14'>
                                    Product Price
                                </div>
                                <div className='d-flex jcc f14 fwb'>
                                    $ {txnObj?.listingDetailsView?.auctionReservePrice ? txnObj?.listingDetailsView?.auctionReservePrice : txnObj?.listingDetailsView?.sellPrice ? txnObj?.listingDetailsView?.sellPrice : txnObj?.listingDetailsView?.tradeReservePrice}
                                </div>
                            </div> */}


                        </div>
                        <div className='px10 txn-card'>
                            <div className='f14'>
                                Product Price
                            </div>
                            <div className='f14'>
                                $ {txnObj?.listingDetailsView?.auctionReservePrice ? txnObj?.listingDetailsView?.auctionReservePrice : txnObj?.listingDetailsView?.sellPrice ? txnObj?.listingDetailsView?.sellPrice : txnObj?.listingDetailsView?.tradeReservePrice}
                            </div>
                        </div>

                        <div className='px10 txn-card'>
                            <div className='f14'>
                                Tax (10%)
                            </div>
                            <div className='f14'>
                                $ {txnObj?.orderDetails?.totalTaxes}
                            </div>
                        </div>
                        <div className='px10 txn-card'>
                            <div className='f14'>
                                Platform Fee
                            </div>
                            <div className='f14'>
                                $ {txnObj?.orderDetails?.platformFee}
                            </div>
                        </div>
                        <div className='px10 txn-card py5'>
                            <div className='f14 fwb'>
                                Settlement Amount
                            </div>
                            <div className='f14 fwb'>
                                $ {txnObj?.settlementAmount}
                            </div>
                        </div>
                        <div className='px10 txn-card py10 bt-ddd'>
                            <div className='f16 font-weight-bold'>
                                Total Price
                            </div>
                            <div className='f16 font-weight-bold'>
                                $ {txnObj?.totalAmount}

                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className='txn-footer py10'>
                        <div className='txn-power-by'>
                            Powered by Authorize.net
                        </div>
                </div> */}

            </div>
        </Slider>
    </Fragment>
    )
}

export default TransactionSlider;