import React, { useEffect, useContext, Fragment, useRef } from 'react'
import _ from 'lodash';
import $ from "jquery";
import { useHistory } from 'react-router-dom'
import { goToTopOfWindow } from '../../../commons/utils';
import { AppContext } from '../../../contexts/AppContext';
import bgCheckmarkIcon from '../../../assets/images/icon/bg_checkmark.png'
import useToast from '../../../commons/ToastHook';
import bgFailureIcon from '../../../assets/images/Failure.png';

const OfferPlaced = ({ setTab, tab, valueToMatch, tabWiseData, product, listingUser }) => {
    const Toast = useToast();
    const history = useHistory()
    const { paymentStatus, setPaymentStatus } = useContext(AppContext);
    let orderStatus = useRef(paymentStatus);

    const goToHome = () => {
        tabWiseData = {
            details: {},
            tradeListItems: [],
            bidInfo: {},
            isViewedSpecificTradeModal: false
        }
        goToTopOfWindow();
        Toast.success({ message: 'Offer Placed Successfully!', time: 2000 });
        history.replace('/')
    }
    const getMyImage = (item) => {
        let imageUrl = '../images/no-image-available.png';
        if (!_.isEmpty(item.listingDetailsContent || item.listing_details_content)) {
            const imagesByItem = JSON.parse(item.listingDetailsContent || item.listing_details_content)[0];
            imageUrl = imagesByItem && imagesByItem.fileName ? imagesByItem.fileName : '../images/no-image-available.png';
        }
        return imageUrl;
    }

    const tryAgainPayment = () => {
        try {
            $("#post").removeClass("active");
            $("#info").addClass("active");
            setTab("info");
        } catch (err) {
            console.error("error occur on tryAgainPayment()", err);
        }
    }

    useEffect(() => {
        orderStatus.current = paymentStatus;
    }, [paymentStatus])

    useEffect(() => {
        $("#info").removeClass("active");
        $("#payment").removeClass("active");
        $("#location").removeClass("active");
        $("#info").removeClass("active");
    }, [])
    return (

        <div class="d-flex flex-column align-items-center justify-content-center">
            {
                orderStatus.current ? <Fragment>
                    <div class="col-lg-4 p-4 col-4">
                        <img src={bgCheckmarkIcon} />
                    </div>
                    <p>You have submitted your offer</p>

                    <div class="col-lg-4">
                        <img class="img-fluid" src={getMyImage(tabWiseData.details)} />
                    </div>
                    <div class="col-lg-7">
                        <div class="row bg-white bg-none justify-content-between">
                            <div class="col-lg-12">
                                <div class="pro-dtle-box">
                                    <h2> {tabWiseData.details.title}{Array.isArray(tabWiseData.tradeListItems) && tabWiseData.tradeListItems.length > 1 && ` & ${tabWiseData.tradeListItems.length - 1}item(s)`}</h2>
                                    <div className="mto-offered-value f12 jcc aic"><span className="pb5 mr5">Offered Items Value : </span><h2>${(tabWiseData.totalTradePriceWith && tabWiseData.totalTradePriceWith) || 0}</h2></div>
                                    {(valueToMatch.isPayBalance && valueToMatch.amount > 0) && <div className="mto-offered-value f12 jcc aic"><span className="pb5 mr5">Offered Amount : </span><h2>${(valueToMatch.isPayBalance && valueToMatch.amount) || 0}</h2></div>}
                                    {(valueToMatch.isPayBalance && valueToMatch.amount > 0) && <p className="pro-description">Once the seller accepts your offer, you will be notified and The amount ${(valueToMatch.isPayBalance && tabWiseData.totalPrice) || 0} (inclusive of tax and platform fees) will be deducted from your selected Payment card</p>}
                                    {(valueToMatch.amount <= 0) && <p className="pro-description">Once the seller accepts your offer, you will be notified and the amount ${(tabWiseData.totalPrice) || 0} (inclusive of tax and platform fees) will be deducted from your selected Payment card.</p>}
                                </div>

                                {!_.isEmpty(listingUser) && listingUser?.appUserType === "INDIVIDUAL" &&
                                    <div className='pro-dtle-box'>
                                        <div>Contact Seller</div>
                                        <div className='c6f6f'>
                                            <span>Name :</span>
                                            <span className='px2'>{listingUser?.firstName}</span><span className='px2'>{listingUser?.lastName}</span>
                                        </div>
                                        <div className='c6f6f'>
                                            <span>E-Mail :</span>
                                            <span className='px2'>{listingUser?.email}</span>
                                        </div>
                                        <div className='c6f6f'>
                                            <span>Phone :</span>
                                            <span className='px2'>{listingUser?.phoneNumber}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mobile-off col-lg-7">
                        <input type="button" name="make_payment" className="next mto-go-to-home dark-button px20" value="Go to Home" onClick={goToHome} />
                    </div>

                    <section class="mobile-btn-section desktop-off">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="proPg-btnArea">
                                        <div className="proPg-btnArea-div-outer">
                                            <div className="proPg-btnArea-div-inner">
                                                <input type="button" name="make_payment" value="Go to Home" onClick={goToHome} class="submt-btn submt-btn-black text-center full-w mto-go-to-home dark-button" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Fragment>
                    : <Fragment>
                        <div class="col-lg-4 p-4 col-4">
                            <img src={bgFailureIcon} alt="ordered" style={{ width: "40px" }} />
                        </div>
                        <h2 class="congrats">Transaction Failed</h2>
                        <div class="col-lg-7">
                            <div class="row bg-white bg-none justify-content-between">
                                <div class="col-lg-12">
                                    <div class="pro-dtle-box">
                                        <p class="pro-description">
                                            Your order in draft.<br /><br />
                                            Try again and complete transactions<br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <input type="button" name="make_payment" class="next dark-button" value="Try Again" onClick={() => { tryAgainPayment() }} />
                        </div>
                    </Fragment>
            }

        </div >
    )
}

export default OfferPlaced;