import React, { useState, Fragment, useEffect, useContext, useRef, memo } from 'react';
import _ from 'lodash';
import $ from "jquery";
import { useHistory } from 'react-router-dom'
import { goToTopOfWindow } from '../../../commons/utils';
import bgCheckmarkIcon from '../../../assets/images/icon/bg_checkmark.png'
import useToast from '../../../commons/ToastHook';
import { AppContext } from '../../../contexts/AppContext';
import bgFailureIcon from '../../../assets/images/Failure.png';

const BidPlaced = ({ setTab, tab, product = {}, tabWiseData, listingUser }) => {
    const Toast = useToast();
    const history = useHistory();
    const { paymentStatus, setPaymentStatus } = useContext(AppContext);

    let orderStatus = useRef(paymentStatus);

    const goToHome = () => {
        tabWiseData = {
            details: {}
        }
        Toast.success({ message: 'Bid Placed Successfully!', time: 2000 });
        goToTopOfWindow();
        history.replace('/')
    }
    const getMyImage = (item) => {
        let imageUrl = '../images/no-image-available.png';
        if (!_.isEmpty(item.listingDetailsContent)) {
            const imagesByItem = JSON.parse(item.listingDetailsContent)[0];
            imageUrl = imagesByItem && imagesByItem.fileName ? imagesByItem.fileName : '../images/no-image-available.png';
        }
        return imageUrl;
    }

    const tryAgainPayment = () => {
        try {
            $("#post").removeClass("active");
            $("#info").addClass("active");
            setTab("info");
        } catch (err) {
            console.error("error occur on tryAgainPayment()", err);
        }
    }

    useEffect(() => {
        orderStatus.current = paymentStatus;
    }, [paymentStatus])

    useEffect(() => {
        $("#info").removeClass("active");
        $("#payment").removeClass("active");
        $("#location").removeClass("active");
        $("#info").removeClass("active");
    }, [])
    return (
        <div class="d-flex flex-column align-items-center justify-content-center">
            {
                orderStatus.current ? <Fragment>
                    <div class="col-lg-4 p-4 col-4">
                        <img src={bgCheckmarkIcon} />
                    </div>
                    <p className="fw600 f16">You have submitted your bid</p>

                    <div class="col-lg-4">
                        <img class="img-fluid" src={getMyImage(product)} />
                    </div>
                    <div class="col-lg-7">
                        <div class="row bg-white bg-none justify-content-between">
                            <div class="col-lg-12">
                                <div class="pro-dtle-box">
                                    <h2> {product.title}</h2>
                                    <div className="pb20 mb20"><span className="f12 mr10 c777">Bid Value : </span><span className="theme-color f18 fw600">${tabWiseData?.postData?.bidValue || tabWiseData.totalPrice ? Number(tabWiseData.totalPrice).toFixed(2) : "-"}</span></div>
                                    <p>Once the seller accepts your bid, you will be notified and The amount <span className="fw600">${tabWiseData.totalPrice ? Number(tabWiseData.totalPrice).toFixed(2) : "-"}</span> (inclusive of tax and platform fees) will be deducted from  your selected Payment card</p>
                                </div>
                                {!_.isEmpty(listingUser) && listingUser?.appUserType === "INDIVIDUAL" &&
                                    <div className='pro-dtle-box'>
                                        <div>Contact Seller</div>
                                        <div className='c6f6f'>
                                            <span>Name :</span>
                                            <span className='px2'>{listingUser?.firstName}</span><span className='px2'>{listingUser?.lastName}</span>
                                        </div>
                                        <div className='c6f6f'>
                                            <span>E-Mail :</span>
                                            <span className='px2'>{listingUser?.email}</span>
                                        </div>
                                        <div className='c6f6f'>
                                            <span>Phone :</span>
                                            <span className='px2'>{listingUser?.phoneNumber}</span>
                                        </div>
                                    </div>
                                }

                                <div className='pro-dtle-box'>
                                    <div>Contact Seller</div>
                                    <div className='c6f6f'>
                                        <span>Name :</span>
                                        <span className='px2'>{listingUser?.firstName}</span><span className='px2'>{listingUser?.lastName}</span>
                                    </div>
                                    <div className='c6f6f'>
                                        <span>E-Mail :</span>
                                        <span className='px2'>{listingUser?.email}</span>
                                    </div>
                                    <div className='c6f6f'>
                                        <span>Phone :</span>
                                        <span className='px2'>{listingUser?.phoneNumber}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-off col-lg-7">
                        <input type="button" name="make_payment" class="next nextBtn dark-button" value="Go to Home" onClick={goToHome} />
                    </div>
                    <section class="mobile-btn-section desktop-off">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="proPg-btnArea">
                                        <div className="proPg-btnArea-div-outer">
                                            <div className="proPg-btnArea-div-inner">
                                                <input type="button" value="Go to Home" onClick={goToHome} class="next nextBtn dark-button" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Fragment>
                    : <Fragment>
                        <div class="col-lg-4 p-4 col-4">
                            <img src={bgFailureIcon} alt="ordered" style={{ width: "40px" }} />
                        </div>
                        <h2 class="congrats">Transaction Failed</h2>
                        <div class="col-lg-7">
                            <div class="row bg-white bg-none justify-content-between">
                                <div class="col-lg-12">
                                    <div class="pro-dtle-box">
                                        <p class="pro-description">
                                            Your order in draft.<br /><br />
                                            Try again and complete transactions<br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <input type="button" name="make_payment" class="next dark-button" value="Try Again" onClick={() => { tryAgainPayment() }} />
                        </div>
                    </Fragment>
            }


        </div>
    )
}

export default memo(BidPlaced);