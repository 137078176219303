import React, { useState, useContext, Fragment, useEffect } from 'react';
import _ from 'lodash';
import Spinner from "rct-tpt-spnr";
import { Form, Dropdown } from 'react-bootstrap';
import ApiService from "../../../services/api.service";
import { useAuthState } from '../../../contexts/AuthContext/context';
import { AppContext } from '../../../contexts/AppContext';
import { useHistory } from 'react-router-dom';
import GLOBAL_CONSTANTS from '../../../Constants/GlobalConstants';
import { MdCloudDownload } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";

import useToast from '../../../commons/ToastHook';
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-datepicker';
import classNames from "classnames";
import PaymentGatewayModal from './PaymentGateway/PaymentGatewayModal';
import { verifyCustomerFromPaymentGateway, generateDealerToken } from './PaymentGateway/PaymentMethod';
import BtnSpinner from 'react-bootstrap/Spinner';
import TransactionSlider from './TransactionDetailsSlider';
import { ICN_FILTER } from '../../icons';
import { IoSettingsOutline } from "react-icons/io5";
import './PaymentSetup.css';


const UnclearedPayment = ({ dealerSid, listingStatus, setListingStatus, getAllDealerList = ()=>{} }) => {

    const Toast = useToast();
    const spinner = useContext(Spinner);
    const { payProcess, setPayProcess } = useContext(AppContext);
    const history = useHistory();
    let userDetails = useAuthState();
    const [showTxn, setShowTxn] = useState(false);
    const [filterPaymentType, setFilterPaymentType] = useState("UNSETTLED");
    const [showOrder, setShowOrder] = useState(false);
    const [show, setShow] = useState(false);
    const [token, setToken] = useState(null);
    const [paymentHistory, setPaymentHistory] = useState(null);
    const [unClearedPayment, setUnclearedPayment] = useState(null);
    const [txnDetails, setTxnDetails] = useState(null);


    const [pageNo, setPageNo] = useState(0);
    const [totalCount, setTotalCount] = useState(35);


    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div className="flx pointer"
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </div>
    ));

    // Thi sis get the payment setup Information.
    const getPaymentHistory = () => {
        try {
            spinner.show("Loading...")
            let payload = {
                "appUserSid": dealerSid ? dealerSid : userDetails?.user?.sid,
                "settlementStatus": filterPaymentType === "null" ? null : filterPaymentType,
            }
            ApiService.getDealerTransaction(payload).then(
                response => {
                    setPaymentHistory(response.data);
                },
                err => {
                    console.error("Error occurred in getPaymentHistory() --", err);

                }
            ).finally(() => {
                spinner.hide();
            });

        } catch (err) {
            console.error("Error occur when getPaymentHistory--", err);
            spinner.hide();
        }
    }

    // Thi sis get the payment setup Information.
    const getUnclearedPayment = (sid) => {
        try {
            spinner.show("Loading...")
            let dealerId = sid ? sid : userDetails?.user?.sid
            ApiService.getDealerTotalAmount(dealerId).then(
                response => {
                    setUnclearedPayment(response.data);
                },
                err => {
                    console.error("Error occurred in getPaymentHistory() --", err);

                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            spinner.hide();
            console.error("Error occur when getUnclearedPayment--", err);
        }
    }

    // Calling the payment method API
    const initPaymentGateway = async () => {
        try {
            let payload = {
                "debitorSid": userDetails?.user?.sid,
                "vendor": GLOBAL_CONSTANTS.PAYMENT.DEFAULT_VENDOR,
                "creditorType": GLOBAL_CONSTANTS.USER_ROLE.SUPERADMIN,
            };

            let verifyPayment = await verifyCustomerFromPaymentGateway(payload);
            if (verifyPayment?.data === true) {

                let tokenPayload = {
                    "dealerSid": userDetails?.user?.sid,
                    "vendor": GLOBAL_CONSTANTS.PAYMENT.DEFAULT_VENDOR,
                };

                let tokenResult = await generateToken(tokenPayload);

                if (tokenResult?.data?.token) {
                    setShow(true);
                    setToken(tokenResult?.data?.token);
                }else{
                    Toast.error({message: "Technical issue, Try again after sometime."});
                }

            }else{
                Toast.error({message: "User authentication failed due to invalid authentication values."});
            }

        } catch (err) {
            console.error("Error occurred while initPaymentGateway -- ", err);
        }
    }

    const generateToken = async (payload) => {
        let returnValue;
        try {
            returnValue = await generateDealerToken(payload);
        } catch (err) {
            console.error("Error occurred while generateToken -- ", err);
        }

        return returnValue;

    }

    const getPaymentStatus = (status) => {
        let returnValue;
        try {
            switch(status){
                case 'CAPTURED':
                    returnValue = "Captured";
                    break;
                case 'VOIDED':
                    returnValue = "Voided";
                    break;
                case 'ON_HOLD':
                    returnValue = "On Hold";
                    break;
            }
        } catch (err) {
            console.error("Error occurred while getPaymentStatus -- ", err);
        }

        return returnValue;

    }

    // Payment Reminder Message
    const generateReminderMessage = (sid) => {
        try {
            spinner.show("Loading...")
            ApiService.sendPaymentReminder(sid).then(
                response => {
                    Toast.success({ message: "Payment reminder successfully", time: 3000 });
                },
                err => {
                    console.error("Error occurred in generateReminderMessage() --", err);

                }
            ).finally(() => {
                spinner.hide();
            });

        } catch (err) {
            console.error("Error occur when generateReminderMessage--", err);
            spinner.hide();
        }
    }

    // change the dealer listing status.
    const blackListingByAdmin = (value) => {
        try {
            spinner.show("Loading...")
            let payload ={
                "appUserSid": dealerSid,
                "status": value,
            };

            ApiService.blackListingByAdmin(payload).then(
                response => {
                    getAllDealerList();
                    Toast.success({ message: "Listing status updated successfully", time: 3000 });
                },
                err => {
                    console.error("Error occurred in blackListingByAdmin() --", err);

                }
            ).finally(() => {
                spinner.hide();
            });

        } catch (err) {
            console.error("Error occur when blackListingByAdmin--", err);
            spinner.hide();
        }
    }



    useEffect(() => {
        getUnclearedPayment(dealerSid);
        dealerSid && setShowTxn(!showTxn);
    }, [dealerSid]);

    useEffect(() => {
        getPaymentHistory();
    }, [dealerSid, filterPaymentType]);


    return <>
        <div className='payment-history'>
            <div className='uncleared-payment-1'>
                <div className=''>
                    <span className='px5' style={{ color: "#111", fontSize: "20px" }}>
                        Uncleared Amount
                    </span>
                    <span className='px5' style={{ color: "#111", fontSize: "14px" }}>
                        (Due in 24 Hrs / Due Past 24 Hrs)
                    </span>
                </div>
                <div className='px5' style={{ color: "#111", fontSize: "20px", fontWeight: 600 }}>
                    $ {unClearedPayment ? unClearedPayment : 0}
                </div>
                <div className=''>
                    {
                        userDetails?.user?.appUserType !== GLOBAL_CONSTANTS.USER_ROLE.SUPERADMIN &&
                        <div
                            className={classNames("submt-btn submt-btn-dark btn-pd cp", { "disabled C111": unClearedPayment <= 0 || payProcess })}
                            onClick={() => { initPaymentGateway(); setPayProcess(true) }}
                        >
                            {
                                payProcess &&
                                <span className='px5'>
                                    <BtnSpinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                            }


                            <span className="visually-hidden px5">Pay</span>
                        </div>
                    }

                </div>

                <div className=''>
                    <div className='cp history-link' onClick={() => { setShowTxn(!showTxn) }}>
                        {
                            showTxn ? "Less transaction history" : "View all transaction history"
                        }
                    </div>
                </div>
            </div>

            {
                showTxn &&
                <div className='transaction-table'>
                    <Fragment>
                        <div className='txn-settings px10 py10'>
                            <div className='txt-settings-left'>
                                <div>
                                    Filter By
                                </div>
                                <div>
                                    <Form.Group className='mb0'>
                                        <Form.Control className="mb0" style={{ fontSize: "14px" }} as="select"
                                            name="filterTypes"
                                            value={filterPaymentType}
                                            onChange={(e) => { e.target.value !== "CustomDate" && setFilterPaymentType(e.target.value) }}
                                        >
                                            <option value="null">All</option>
                                            <option value="UNSETTLED">Unsettled</option>
                                            <option value="SETTLED">Settled</option>
                                            <option value="CustomDate">Custom Date</option>

                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                {
                                    filterPaymentType === "CustomDate" &&
                                    <div className='custom-date'>
                                        <div>
                                            <Form.Group className='mb0'>
                                                <DatePicker
                                                    className="form-control mb0 fc-pd3"
                                                    // selected={values?.licenseExpireOn ? new Date(values.licenseExpireOn) : null}
                                                    minDate={new Date()}
                                                    dateFormat="MM/dd/yyyy"
                                                    placeholderText="MM/DD/YYYY"
                                                // onChange={(e) => {
                                                //     setFieldValue("licenseExpireOn", e.toISOString());
                                                // }}
                                                // onBlur={handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {/* {errors.licenseExpireOn} */}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                        <div>
                                            <Form.Group className='mb0'>
                                                <DatePicker
                                                    className="form-control mb0 fc-pd3"
                                                    // selected={values?.licenseExpireOn ? new Date(values.licenseExpireOn) : null}
                                                    minDate={new Date()}
                                                    dateFormat="MM/dd/yyyy"
                                                    placeholderText="MM/DD/YYYY"
                                                // onChange={(e) => {
                                                //     setFieldValue("licenseExpireOn", e.toISOString());
                                                // }}
                                                // onBlur={handleBlur}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {/* {errors.licenseExpireOn} */}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className='txt-settings-right'>
                                {/* <div className='report-btn px10 py5 cp'> */}
                                {/* <span className='px5'><MdCloudDownload /></span>
                                    <span className='px5'>Report</span> */}
                                <span className='settings-right flx jcc aic p4'>
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} id="create-update-listing">
                                            <IoSettingsOutline />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                userDetails?.user?.appUserType === GLOBAL_CONSTANTS.USER_ROLE.SUPERADMIN &&
                                                <Dropdown.Item
                                                    className={classNames("", { "disabled C111": unClearedPayment <= 0 })}
                                                    onClick={() => { generateReminderMessage(dealerSid) }}
                                                >
                                                    Payment Reminder
                                                </Dropdown.Item>
                                            }
                                            {
                                                userDetails?.user?.appUserType === GLOBAL_CONSTANTS.USER_ROLE.SUPERADMIN &&
                                                <Dropdown.Item className=''>
                                                    <span>Listing</span>
                                                    <span className='float-right' onClick={()=> blackListingByAdmin(listingStatus === "ENABLED" ? "DISABLE" : "ENABLE")}>
                                                        <label className="gen-switch ibvm cp ">
                                                            <input
                                                                type="checkbox"
                                                                checked={listingStatus === "ENABLED" ? true : false}
                                                                onChange={(e) => console.log(e.target.checked) } /> 
                                                            <div className="gen-slider round"></div>
                                                        </label>
                                                    </span>
                                                    {/* blackListingByAdmin(listingStatus === "ENABLED" ? "DISABLED" : "ENABLED") */}
                                                </Dropdown.Item>
                                            }
                                            <Dropdown.Item className='disabled'>Download Report</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </span>
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="history-table">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Order Id</th>
                                        <th>Txn Id</th>
                                        <th>Date & Time</th>
                                        <th>Amount($)</th>
                                        <th>Payment Status</th>
                                        <th>Settlement Status</th>
                                        <th>Txn Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        (!_.isEmpty(paymentHistory) && _.isArray(paymentHistory)) ?
                                            paymentHistory.map((list, idx) => {
                                                return <tr key={`trn-${idx}`}>
                                                    <td className='history-link p5 cp' onClick={() => { setShowOrder(true); setTxnDetails(list) }}>
                                                        {list?.orderDetails?.id}
                                                    </td>
                                                    <td className='p5'>{list?.transactionId}</td>
                                                    <td className='p5'>{list?.dateTime}</td>
                                                    <td className='p5'>{list?.settlementAmount}</td>
                                                    <td className='p5'>{getPaymentStatus(list?.paymentStatus)}</td>
                                                    <td className='p5'>
                                                        <div className={classNames("tb-status pmt-success px5", { "pmt-failed": list?.settlementStatus !== "SETTLED" })}>
                                                            <span className='tb-status-icon'>
                                                                {
                                                                    list?.settlementStatus === "SETTLED" ? <FaCheckCircle /> : <AiFillCloseCircle />
                                                                }

                                                            </span>
                                                            <span className='px5'>
                                                                {list?.settlementStatus}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className='p5'>
                                                        <div className={classNames("tb-status pmt-success px5", { "pmt-failed": list?.transactionStatus !== "SUCCESS" })}>
                                                            <span className='tb-status-icon'>
                                                                {
                                                                    list?.transactionStatus === "SUCCESS" ? <FaCheckCircle /> : <AiFillCloseCircle />
                                                                }

                                                            </span>
                                                            <span className='px5'>
                                                                {list?.transactionStatus}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })

                                            :
                                            <span className='flx jcc'>Transaction are not available</span>
                                    }

                                </tbody>
                            </Table>
                            {/* <div className="text-center jcc">
                                <Pagination {...{
                                    pageCount: 10,
                                    totalCount,
                                    onNavigate: (pn) => {
                                        setPageNo(pn)
                                    }
                                }} />
                            </div> */}
                        </div>
                    </Fragment>
                </div>
            }

            {showOrder &&
                <TransactionSlider {...{ show: showOrder, setShow: setShowOrder, txnInfo: txnDetails, setTxnDetails }} />
            }

            {
                show &&
                <PaymentGatewayModal {...{ show: show, setShow, token: token, setToken, fromWhere: userDetails?.user?.appUserType, getUnclearedPayment, getPaymentHistory }} />
            }

        </div>
    </>;
}

export default UnclearedPayment;