import ApiService from "../../../../services/api.service";


/**
 * Generate a valid token from the buy
 */
export const generateBuyToken = async (payload) =>{
    let token = '';
    try{
       token = await ApiService.getBuyToken(payload);

    }catch(err){
        console.error("Error occurred while generateBuyToken -- ", err);
    }
    return token;
}

/**
 * Generate a valid token from the buy
 */
export const generateBidToken = async (payload) =>{
    let token = '';
    try{
       token = await ApiService.getBidToken(payload);

    }catch(err){
        console.error("Error occurred while generateBidToken -- ", err);
    }
    return token;
}

/**
 * Generate a valid token from the buy
 */
export const generateTradeToken = async (payload) =>{
    let token = '';
    try{
       token = await ApiService.getTradeToken(payload);

    }catch(err){
        console.error("Error occurred while generateTradeToken -- ", err);
    }
    return token;
}

/**
 * Generate a valid token from the counter trade.
 */
export const generateCounterTradeToken = async (payload) =>{
    let token = '';
    try{
       token = await ApiService.getCounterTradeToken(payload);

    }catch(err){
        console.error("Error occurred while generateCounterTradeToken -- ", err);
    }
    return token;
}

/**
 * Generate a valid token from the dealer
 */
export const generateDealerToken = async (payload) =>{
    let token = '';
    try{
       token = await ApiService.getDealerToken(payload);

    }catch(err){
        console.error("Error occurred while generateDealerToken -- ", err);
    }
    return token;
}


/**
 * Verify the customer from payment gateway.
 */
export const verifyCustomerFromPaymentGateway = async (payload) =>{
    let status = '';
    try{
        status = await ApiService.verifyPaymentGateway(payload);
    }catch(err){
        console.error("Error occurred while verifyCustomerFromPaymentGateway -- ", err);
    }
    return status;
}
